<template>
  <section>
    <!-- Hero -->
    <base-page-heading
      :title="
        $t('order.title.type_' + order.order_type) +
          ' ' +
          orderId +
          ' - egfB2B Innendienst'
      "
    >
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              :to="{
                name: 'manufactureHome',
              }"
              >zurück zur Übersicht</router-link
            >
          </b-breadcrumb-item>
        </b-breadcrumb>
      </template>
      <template #sub v-if="order.parent_order_str">
        <i class="fa fa-long-arrow-alt-right" />
        {{ $t("order.parentOrder") }}

        <router-link
          class="font-w600"
          v-if="order.parent_order_str"
          :to="{
            name: 'manufactureOrder',
            params: {
              orderid: order.parent_order_str,
            },
          }"
          @click.native="reload(order.parent_order_str)"
        >
          {{ order.parent_order_str }}
        </router-link>
      </template>
      <template #sub v-else-if="order.child_orders.length > 0">
        <div
          v-for="(child_order, child_key) in order.child_orders"
          :key="child_key"
        >
          <i class="fa fa-long-arrow-alt-right" />
          {{ $t("order.title.type_" + child_order.order_type) }}
          <router-link
            class="font-w600"
            v-if="child_order.order_id_str"
            :to="{
              name: 'manufactureOrder',
              params: {
                orderid: child_order.order_id_str,
              },
            }"
            @click.native="reload(child_order.order_id_str)"
          >
            {{ child_order.order_id_str }}
          </router-link>
        </div>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- ORDER -->
    <div class="content" v-if="isOrderIdValid === true">
      <b-row v-if="order.order_type == 'S'">
        <b-col sm="12">
          <OrderServiceHeader :order="order" />
        </b-col>
      </b-row>
      <!-- ORDER STATUS -->
      <b-row>
        <b-col sm="12">
          <OrderStatusTimeline :order="order" division="manufacture" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <OrderActions :order="order" division="manufacture" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <OrderPrints :order="order" division="manufacture" />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <base-block
            :title="$t('order.data.title')"
            header-class="bg-primary"
            themed
          >
            <b-table-simple
              responsive
              borderless
              small
              table-class="table-vcenter"
            >
              <b-tbody>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.commission") }}:
                  </b-td>
                  <b-td>
                    <span>
                      {{ order.commission }}
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.deliveryDateCustomer") }}:
                  </b-td>
                  <b-td>
                    <div>
                      <span v-if="order.delivery_date_customer !== null">{{
                        $d(new Date(order.delivery_date_customer), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.deliveryDate") }}:
                  </b-td>
                  <b-td>
                    <div>
                      <span v-if="order.delivery_date_supplier != null">{{
                        $d(new Date(order.delivery_date_supplier), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-if="order.order_type == 'A'">
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.weddingDate") }}:
                  </b-td>
                  <b-td>
                    <div>
                      <span v-if="order.wedding_date !== null">{{
                        $d(new Date(order.wedding_date), "short")
                      }}</span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("order.data.servedBy") }}:
                  </b-td>
                  <b-td>
                    <span>
                      <span v-if="order.salesperson !== null">
                        {{ order.salesperson }}
                      </span>
                      <span v-else>{{ $t("order.data.notSpecified") }}</span>
                    </span>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2" class="pt-4 font-w600">
                    <b-form-group
                      :label="$t('order.data.notesCustomer')"
                      label-class="font-w600"
                    >
                      <b-form-textarea
                        id="notes"
                        rows="4"
                        :placeholder="$t('order.data.notesCustomerPlaceholder')"
                        v-model="order.notes"
                        disabled
                      >
                      </b-form-textarea>
                    </b-form-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2" class="font-w600">
                    <b-form-group
                      :label="$t('order.data.notesInternal')"
                      label-class="font-w600"
                    >
                      <b-form-textarea
                        id="notesInternal"
                        rows="4"
                        :placeholder="$t('order.data.notesInternalPlaceholder')"
                        v-model="order.notes_internal"
                        disabled
                      ></b-form-textarea>
                    </b-form-group>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <base-block
            :title="$t('order.article.title')"
            header-class="bg-primary"
            themed
          >
            <b-row>
              <b-col
                sm="6"
                v-for="(item, itemKey) in order.items"
                :key="itemKey"
              >
                <base-block rounded>
                  <b-row>
                    <b-col sm="12">
                      <base-block
                        :title="'Artikel ' + (itemKey + 1)"
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="image"
                        style="height: auto;"
                      >
                        <b-img
                          fluid
                          rounded
                          class="py-1 px-4"
                          style="border: 1px solid #ededed;"
                          :src="item.details.image[0]"
                        />
                      </base-block>

                      <base-block
                        :title="$t('order.article.article')"
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="article"
                        style="height: auto;"
                        :style="{ height: layout.articleBlockHeight }"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.name") }}:
                              </b-td>
                              <b-td>
                                {{ item.name }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600">
                                {{ $t("order.article.size") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.size }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.origin_id">
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.configuration") }}:
                              </b-td>
                              <b-td>
                                {{ order.origin_id }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.itemnumber") }}:
                              </b-td>
                              <b-td>
                                {{ item.item_number }}
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.article.engravings')"
                        header-class="bg-primary"
                        class="pt-4"
                        themed
                        ref="engraving"
                        style="height: auto;"
                        :style="{ height: layout.engravingBlockHeight }"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <template v-if="item.engravings.length < 1">
                            <b-tbody>
                              <b-tr>
                                <b-td class="font-w600" style="width: 35%">
                                  {{ $t("order.article.engraving") }}:
                                </b-td>
                                <b-td>
                                  {{ $t("order.article.noEngraving") }}
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </template>
                          <template v-else>
                            <b-tbody
                              v-for="(engraving, i) in item.engravings"
                              :key="'engraving_id_' + i"
                            >
                              <b-tr>
                                <b-td colspan="2">
                                  <hr v-if="i > 0" />
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600" style="width: 35%">
                                  {{ $t("order.article.engravingPosition") }}:
                                </b-td>
                                <b-td>
                                  {{ engraving.side_text }}
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600">
                                  {{ $t("order.article.engravingText") }}:
                                </b-td>
                                <b-td
                                  v-if="engraving.font_id !== '---'"
                                  :class="'engraving-font-' + engraving.font_id"
                                >
                                  {{ engraving.text }}
                                </b-td>
                                <b-td v-else>
                                  ---
                                </b-td>
                              </b-tr>
                              <b-tr>
                                <b-td class="font-w600">
                                  {{ $t("order.article.engravingFont") }}:
                                </b-td>
                                <b-td
                                  v-if="engraving.font_id !== '---'"
                                  :class="'engraving-font-' + engraving.font_id"
                                >
                                  {{ engraving.font_id }}
                                </b-td>
                                <b-td v-else>
                                  ---
                                </b-td>
                              </b-tr>
                              <template
                                v-if="engraving.individual_id && 1 == 17"
                              >
                                <b-tr>
                                  <b-td class="font-w600">
                                    {{
                                      $t("order.article.engravingIndividual")
                                    }}:
                                  </b-td>
                                  <b-td>
                                    {{ engraving.individual_id }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td class="font-w600" colspan="2">
                                    <img
                                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4
        //8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
                                    />
                                  </b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </template>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.article.details.title')"
                        header-class="bg-primary"
                        class="pt-1"
                        style="height: auto;"
                        :style="{ height: layout.detailsBlockHeight }"
                        themed
                        ref="details"
                      >
                        <b-table-simple
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <b-tbody>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.brand") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.brand }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600">
                                {{ $t("order.article.details.colorMetal") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.metal }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.profile") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.profile }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.width") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.width }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600" style="width: 35%">
                                {{ $t("order.article.details.height") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.height }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="font-w600">
                                {{ $t("order.article.details.finish") }}:
                              </b-td>
                              <b-td>
                                {{ item.details.details.finish }}
                              </b-td>
                            </b-tr>
                            <b-tr v-if="order.order_type != 'P'">
                              <b-td class="font-w600">
                                {{ $t("order.article.details.grooves") }}:
                              </b-td>
                              <b-td>
                                <span
                                  v-if="item.details.details.grooves.length > 0"
                                >
                                  <span
                                    v-for="(grooves, i) in item.details.details
                                      .grooves"
                                    :key="i"
                                    >{{ grooves }}<br
                                  /></span>
                                </span>
                                <span v-else>
                                  ({{ $t("order.article.details.noGrooves") }}:)
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td colspan="2"><hr /></b-td>
                            </b-tr>
                            <template
                              v-for="(stone, i) in item.details.details.stones"
                            >
                              <b-tr :key="i">
                                <b-td class="font-w700">
                                  {{ $t("order.article.details.stones") }}:
                                </b-td>
                                <b-td> &nbsp; </b-td>
                              </b-tr>
                              <b-tr :key="i + '_setting'">
                                <b-td class="font-w600">
                                  {{
                                    $t("order.article.details.stoneSetting")
                                  }}:
                                </b-td>
                                <b-td> {{ stone.setting }} </b-td>
                              </b-tr>
                              <b-tr :key="i + '_quality'">
                                <b-td class="font-w600">
                                  {{
                                    $t("order.article.details.stoneQuality")
                                  }}:
                                </b-td>
                                <b-td> {{ stone.quality }} </b-td>
                              </b-tr>
                              <b-tr :key="i + '_carat'">
                                <b-td class="font-w600">
                                  {{ $t("order.article.details.stoneCarat") }}:
                                </b-td>
                                <b-td> {{ stone.carat }} </b-td>
                              </b-tr>
                            </template>
                          </b-tbody>
                        </b-table-simple>
                      </base-block>

                      <base-block
                        :title="$t('order.defaultSample')"
                        header-class="bg-primary"
                        class="pt-1"
                        style="height: auto;"
                        themed
                        v-if="order.order_type == 'P'"
                      >
                        <p>
                          <span class="font-w600"
                            >{{
                              $t("orderCreateSample.price.default.sample")
                            }}:</span
                          ><br />
                          {{
                            $t(
                              "orderCreateSample.price.default.sampleDescription",
                            )
                          }}
                        </p>
                      </base-block>

                      <base-block
                        :title="
                          $t(
                            'order.infoForManufacture.title.type_' +
                              order.order_type,
                          )
                        "
                        header-class="bg-primary"
                        class="manufacturing-notes pt-1"
                        style="height: auto;"
                        :style="{
                          height: layout.manufacturingNotesBlockHeight,
                        }"
                        themed
                        ref="manufacturingNotes"
                      >
                        <b-form-textarea
                          rows="4"
                          :placeholder="
                            $t(
                              'order.infoForManufacture.textarea.type_' +
                                order.order_type,
                            )
                          "
                          v-model="item.manufacturing_notes"
                          class="p-2"
                          disabled
                        ></b-form-textarea>
                      </base-block>
                    </b-col>
                  </b-row>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
    </div>

    <div v-if="isOrderIdValid === false">
      <b-row>
        <b-col sm="12">
          <base-block>
            <div
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-dismissible alert-danger"
            >
              <h3 class="alert-heading h4 my-2">
                {{ $t("order.error.title") }}
              </h3>

              <p class="mb-0">
                {{ $t("order.error.text") }}<br /><br />
                <router-link
                  :to="{
                    name: 'manufactureHome',
                  }"
                >
                  &gt; {{ $t("order.error.backLink") }}
                </router-link>
              </p>
            </div>
          </base-block>
        </b-col>
      </b-row>
    </div>

    <!-- END ORDER -->
  </section>
</template>

<script>
import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import ManufactureOrder from "@/assets/js/ManufactureOrder";

import Engraving from "@/assets/js/Engraving";
import OrderStatusTimeline from "@/components/OrderStatusTimeline.vue";
import OrderActions from "@/components/OrderActions.vue";
import OrderPrints from "@/components/OrderPrints.vue";

export default {
  name: "OrderDetails",
  components: { OrderStatusTimeline, OrderActions, OrderPrints },
  data() {
    return {
      isOrderIdValid: false,
      layout: {
        articleBlockHeightInt: null,
        articleBlockHeight: null,
        detailsBlockHeightInt: null,
        detailsBlockHeight: null,
      },
      orderId: "",
      shopId: "",
      order: {
        order_year: 0,
        order_month: 0,
        order_id: 0,
        order_type: "A",
        parent_order_str: null,
        child_orders: [],
        shop_id: 0,
        type: "",
        commission: "",
        delivery_date_supplier: "2021-01-01",
        delivery_date_customer: "2021-01-01",
        wedding_date: "2021-01-01",
        salesperson: "",
        deposit: 0,
        notes: "",
        notes_internal: "",
        vat: 0,
        currency: "",
        total_amount: 0,
        total_vat_amount: 0,
        status: {
          created: 0,
          created_date: "2021-01-01",
          down_payment: 0,
          down_payment_date: "2021-01-01",
          completely_paid: 0,
          completely_paid_date: "2021-01-01",
          sent_to_supplier: 0,
          sent_to_supplier_date: "2021-01-01",
          in_production: 0,
          in_production_date: "2021-01-01",
          production_finished: 0,
          production_finished_date: "2021-01-01",
          sent_to_customer: 0,
          sent_to_customer_date: "2021-01-01",
          in_shop: 0,
          in_shop_date: "2021-01-01",
          finished: 0,
          finished_date: "2021-01-01",
          cancelled: 0,
          cancelled_date: "2021-01-01",
        },
        items: [
          {
            item_id: 0,
            image: [],
          },
        ],
        origin: "",
        origin_id: "",
      },
    };
  },
  methods: {
    reload(orderid) {
      this.supplierId = 1000;
      this.orderId = orderid;
      this.show(this.supplierId, this.orderId);
    },
    loadData(supplierId, orderId) {
      return ManufactureOrder.get(supplierId, orderId);
    },
    show(supplierId, orderId) {
      Loading.dispatch("increase", 1);

      this.loadData(supplierId, orderId)
        .then(data => {
          this.order = data;
          this.isOrderIdValid = true;

          /* load Fonts */
          for (var key in this.order.items) {
            let item = this.order.items[key];

            for (var engravingKey in item["engravings"]) {
              let fontId = item["engravings"][engravingKey]["font_id"];

              if (fontId != "---") {
                Engraving.loadFont(fontId);
              }
            }
          }

          Loading.dispatch("reduce", 2);
        })
        .then(() => this.matchArticleHeight())
        .then(() => this.matchEngravingHeight())
        .then(() => this.matchDetailsHeight())
        .catch(function(err) {
          // There was an error
          console.log("No order data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    matchArticleHeight() {
      if (typeof this.$refs.article != "undefined") {
        if (
          typeof this.$refs.article[0] !== "undefined" &&
          typeof this.$refs.article[0].$el.clientHeight != "undefined"
        )
          this.layout.articleBlockHeightInt = this.$refs.article[0].$el.clientHeight;
        {
          this.layout.articleBlockHeight =
            this.layout.articleBlockHeightInt + "px";
          let numberOfArticles = this.$refs.article.length;
          if (numberOfArticles > 1) {
            let i = 0;
            for (i = 0; i < numberOfArticles; i++) {
              if (
                this.$refs.article[i].$el.clientHeight >
                this.layout.articleBlockHeightInt
              ) {
                this.layout.articleBlockHeightInt = this.$refs.article[
                  i
                ].$el.clientHeight;
                this.layout.articleBlockHeight =
                  this.layout.articleBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
    matchEngravingHeight() {
      if (typeof this.$refs.engraving != "undefined") {
        if (
          typeof this.$refs.engraving[0] !== "undefined" &&
          typeof this.$refs.engraving[0].$el.clientHeight != "undefined"
        )
          this.layout.engravingBlockHeightInt = this.$refs.engraving[0].$el.clientHeight;
        {
          this.layout.engravingBlockHeight =
            this.layout.engravingBlockHeightInt + "px";
          let numberOfArticles = this.$refs.engraving.length;
          if (numberOfArticles > 1) {
            let i = 0;
            for (i = 0; i < numberOfArticles; i++) {
              if (
                this.$refs.engraving[i].$el.clientHeight >
                this.layout.engravingBlockHeightInt
              ) {
                this.layout.engravingBlockHeightInt = this.$refs.engraving[
                  i
                ].$el.clientHeight;
                this.layout.engravingBlockHeight =
                  this.layout.engravingBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
    matchDetailsHeight() {
      if (typeof this.$refs.details != "undefined") {
        if (
          typeof this.$refs.details[0] !== "undefined" &&
          typeof this.$refs.details[0].$el.clientHeight != "undefined"
        ) {
          this.layout.detailsBlockHeightInt = this.$refs.details[0].$el.clientHeight;
          this.layout.detailsBlockHeight =
            this.layout.detailsBlockHeightInt + "px";
          let numberOfDetails = this.$refs.details.length;
          if (numberOfDetails > 1) {
            let i = 0;
            for (i = 0; i < numberOfDetails; i++) {
              if (
                this.$refs.details[i].$el.clientHeight >
                this.layout.detailsBlockHeightInt
              ) {
                this.layout.detailsBlockHeightInt = this.$refs.details[
                  i
                ].$el.clientHeight;
                this.layout.detailsBlockHeight =
                  this.layout.detailsBlockHeightInt + "px";
              }
            }
          }
        }
      }
    },
  },
  watch: {},
  computed: {
    VUE_APP_BUILD_ENV() {
      return process.env.VUE_APP_BUILD_ENV;
    },
  },
  mounted() {
    document.title = "egfB2B Innendienst Auftrag";

    this.supplierId = "1000";
    this.orderId = this.$route.params.orderid;

    Notification.init(this);

    this.show(this.supplierId, this.orderId);
  },
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";
</style>
