<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('orders.searchResult.title')">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              :to="{
                name: 'manufactureHome',
              }"
              >zurück zur Übersicht</router-link
            >
          </b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content content-narrow">
      <b-row>
        <!-- Search Result Orders -->
        <b-col xl="12">
          <base-block rounded header-bg content-full>
            <b-row class="mb-4">
              <b-col sm="12">
                <b-table-simple
                  striped
                  hover
                  borderless
                  class="table-vcenter font-size-sm mb-0"
                >
                  <b-thead>
                    <b-tr>
                      <b-th
                        class="font-w700 cursor-pointer"
                        @click="tableSortBy('order_id_str')"
                        :class="{
                          'text-black': tableSortKey == 'order_id_str',
                        }"
                        >Auftragsnr.
                        <i
                          v-if="tableSortKey !== 'order_id_str'"
                          class="fa fa-angle-down opacity-medium"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'order_id_str' &&
                              tableSortDirection == 'asc'
                          "
                          class="fa fa-angle-down"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'order_id_str' &&
                              tableSortDirection == 'desc'
                          "
                          class="fa fa-angle-up"
                        ></i>
                      </b-th>
                      <b-th
                        class="d-none d-sm-table-cell font-w700 cursor-pointer"
                      >
                        Shop
                      </b-th>
                      <b-th
                        class="d-none d-sm-table-cell font-w700 cursor-pointer"
                        @click="tableSortBy('delivery_date_supplier')"
                        :class="{
                          'text-black':
                            tableSortKey == 'delivery_date_supplier',
                        }"
                        >Liefertermin
                        <i
                          v-if="tableSortKey !== 'delivery_date_supplier'"
                          class="fa fa-angle-down opacity-medium"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'delivery_date_supplier' &&
                              tableSortDirection == 'asc'
                          "
                          class="fa fa-angle-down"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'delivery_date_supplier' &&
                              tableSortDirection == 'desc'
                          "
                          class="fa fa-angle-up"
                        ></i
                      ></b-th>
                      <b-th
                        class="font-w700 cursor-pointer"
                        @click="tableSortBy('commission')"
                        :class="{ 'text-black': tableSortKey == 'commission' }"
                        >Kommission
                        <i
                          v-if="tableSortKey !== 'commission'"
                          class="fa fa-angle-down opacity-medium"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'commission' &&
                              tableSortDirection == 'asc'
                          "
                          class="fa fa-angle-down"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'commission' &&
                              tableSortDirection == 'desc'
                          "
                          class="fa fa-angle-up"
                        ></i
                      ></b-th>
                      <b-th
                        class="font-w700 cursor-pointer"
                        @click="tableSortBy('current_status')"
                        :class="{
                          'text-black': tableSortKey == 'current_status',
                        }"
                        >Status
                        <i
                          v-if="tableSortKey !== 'current_status'"
                          class="fa fa-angle-down opacity-medium"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'current_status' &&
                              tableSortDirection == 'asc'
                          "
                          class="fa fa-angle-down"
                        ></i>
                        <i
                          v-if="
                            tableSortKey == 'current_status' &&
                              tableSortDirection == 'desc'
                          "
                          class="fa fa-angle-up"
                        ></i>
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="filteredList.length">
                    <b-tr
                      v-for="order in filteredList"
                      :key="order.order_id_str"
                    >
                      <b-td>
                        <router-link
                          class="font-w600"
                          v-if="order.order_id_str"
                          :to="{
                            name: 'manufactureOrder',
                            params: {
                              orderid: order.order_id_str,
                            },
                          }"
                        >
                          {{ order.order_id_str }}
                        </router-link>
                      </b-td>
                      <b-td class="d-none d-sm-table-cell">
                        {{ order.shop_id }}
                      </b-td>
                      <b-td class="d-none d-sm-table-cell">
                        {{
                          $d(new Date(order.delivery_date_supplier), "short")
                        }}
                      </b-td>
                      <b-td>
                        {{ order.commission }}
                      </b-td>
                      <b-td>
                        {{ $t("orders.status." + order.current_status) }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tbody v-else>
                    <tr>
                      <td colspan="4">
                        {{ $t("orders.searchResult.noResults") }}
                      </td>
                    </tr>
                  </b-tbody>

                  <b-tfoot>
                    <b-tr>
                      <b-td colspan="4">
                        Anzahl Aufträge: <b>{{ filteredList.length }}</b>
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <!-- END Search Result Orders -->
      </b-row>
    </div>
    <!-- END Page Content -->
  </section>
</template>

<script>
import _ from "lodash";

import Loading from "@/store/loading";
import ManufactureOrdersSearch from "@/assets/js/ManufactureOrdersSearch";

export default {
  name: "OrdersSearch",

  data() {
    return {
      shopId: null,
      searchString: "",
      filter: "all",
      tableSortKey: "order_id_str",
      tableSortDirection: "asc",
      searchResult: [],
    };
  },
  methods: {
    loadData(searchString) {
      return ManufactureOrdersSearch.getSearchResult(searchString);
    },
    showList(searchString) {
      Loading.dispatch("increase", 1);

      this.loadData(searchString)
        .then(data => {
          this.searchResult = [];
          for (let orderData of data) {
            this.searchResult.push(orderData);
          }
          Loading.dispatch("reduce", 1);
        })
        .catch(function(err) {
          // There was an error
          console.log("No search results available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    tableSortBy(sortKey) {
      if (sortKey == this.tableSortKey) {
        if (this.tableSortDirection == "asc") {
          this.tableSortDirection = "desc";
        } else {
          this.tableSortDirection = "asc";
        }
      } else {
        this.tableSortKey = sortKey;
        this.tableSortDirection = "asc";
      }
    },
  },
  computed: {
    numberOfSearchResults() {
      return this.searchResult.length();
    },
    searchQuery() {
      return this.$route.query.search;
    },
    filteredList() {
      if (this.filter != "all" && this.filter != "") {
        return _.orderBy(
          this.searchResult.filter(
            order => !order.current_status.indexOf(this.filter),
          ),
          [this.tableSortKey],
          [this.tableSortDirection],
        );
      }

      return _.orderBy(
        this.searchResult,
        [this.tableSortKey],
        [this.tableSortDirection],
      );
    },
  },
  watch: {
    searchQuery() {
      if (this.searchQuery !== "") {
        this.showList(this.searchQuery);
      }
    },
  },
  mounted() {
    document.title =
      "egfB2B Innendienst - " + this.$t("orders.searchResult.title");

    if (this.searchQuery) {
      this.showList(this.searchQuery);
    }
  },
};
</script>
