<template>
  <div>
    <!-- Page Content -->
    <div class="content">
      <!-- Hero -->
      <base-block rounded content-full>
        <div class="py-1 text-center">
          <h1 class="mb-0 my-0 py-0">
            {{ $t("customer.orderConfirmation.title") }}
          </h1>
        </div>
      </base-block>
      <!-- END Hero -->

      <!-- Order Confirmation -->
      <base-block
        :title="$t('customer.orderConfirmation.success')"
        header-class="bg-primary text-center"
        themed
      >
        <p class="text-center">
          {{ $t("customer.orderConfirmation.thanks") }}<br />
          <span class="small"
            >{{ $t("customer.orderConfirmation.orderNumber") }}:
            {{ orderId }}</span
          >
        </p>
      </base-block>

      <base-block
        :title="$t('customer.orderConfirmation.personalOrderConfirmation')"
        class="text-center"
      >
        <b-row>
          <b-col>
            <b-button
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-2 btn-block"
              @click="downloadPdf()"
            >
              <i class="fa fa-download mr-1"></i>
              {{ $t("customer.orderConfirmation.downloadPdf") }} <br />
            </b-button>
          </b-col>
          <b-col>
            <b-button
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-2 btn-block"
              @click="showPdf()"
            >
              <i class="fa fa-print mr-1"></i>
              {{ $t("customer.orderConfirmation.openPdf") }} <br />
            </b-button>
          </b-col>
        </b-row>
      </base-block>

      <base-block rounded content-full>
        <b-carousel controls :interval="5000">
          <b-carousel-slide
            img-src="/img/photos/egf-manufaktur-1.jpg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="/img/photos/egf-manufaktur-2.jpg"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="/img/photos/egf-manufaktur-3.jpg"
          ></b-carousel-slide>
        </b-carousel>
      </base-block>

      <base-block
        :title="$t('customer.orderConfirmation.finally')"
        class="text-center"
      >
        <b-row>
          <b-col>
            <router-link
              v-if="shopId && orderId"
              :to="{
                name: 'OrderDetail',
                params: { shopid: shopId, orderid: orderId },
              }"
            >
              <b-button
                size="md"
                variant="outline-secondary"
                class="m-1 mb-4 px-2 btn-block"
              >
                <i class="si si-book-open mr-1"></i>
                {{ $t("customer.orderConfirmation.buttonToOrder") }}
              </b-button>
            </router-link>
          </b-col>
          <b-col>
            <b-button
              v-if="sentToManufacture === false"
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-2 btn-block"
              @click="sendToManufacture()"
            >
              <i class="fa fa-paper-plane mr-1"></i>
              {{ $t("customer.orderConfirmation.buttonToManufakture") }}
            </b-button>
            <b-button
              v-else
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-2 btn-block"
              disabled
            >
              <i class="fa fa-check mr-1"></i>
              {{ $t("customer.orderConfirmation.sentToManufacture") }}
            </b-button>
          </b-col>
        </b-row>
      </base-block>
      <!-- END Order Confirmation -->
    </div>
    <!-- END Page Content -->
  </div>
</template>

<script>
import Loading from "@/store/loading";
import Order from "@/assets/js/Order";
import OrderConfirmation from "@/assets/js/OrderConfirmation";

export default {
  name: "CustomerOrderConfirmation",
  data() {
    return {
      shopId: this.$route.params.shopid,
      orderId: this.$route.params.orderid,
      sentToManufacture: false,
    };
  },
  mounted() {
    Loading.dispatch("reduce", 1);
  },
  methods: {
    async downloadPdf() {
      Loading.dispatch("increase", 1);
      const data = await OrderConfirmation.openPdf(
        this.shopId,
        this.orderId,
        this.$i18n.locale,
      );
      if (data.ok === true) {
        const url = data.url;
        this.pdfUrl = url;

        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.orderId}.pdf`;
        link.click();

        Loading.dispatch("reduce", 1);

        // PDF wurde erfolgreich heruntergeladen
        this.showSuccessAlert(
          this.$t("customer.orderConfirmation.downloadPdfSuccessful"),
        );
      }
    },
    async showPdf() {
      Loading.dispatch("increase", 1);
      const data = await OrderConfirmation.openPdf(
        this.shopId,
        this.orderId,
        this.$i18n.locale,
        "inline",
      );
      if (data.ok === true) {
        Loading.dispatch("reduce", 1);
        const url = data.url;
        window.open(url);
      }
    },
    async sendToManufacture() {
      const data = await Order.setStatus(
        this.shopId,
        this.orderId,
        "sent_to_supplier",
        1,
      );
      if (data.ok === true) {
        this.showSuccessAlert(
          this.$t("customer.orderConfirmation.sentToManufactureLong"),
        );
        this.sentToManufacture = true;
      }
    },
    showSuccessAlert(message) {
      this.$swal(this.$t("notification.success"), message, "success");
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";
@import "~sweetalert2/dist/sweetalert2.min.css";
</style>
