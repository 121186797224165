import Fetch from "@/assets/js/Fetch";

class User {
  get(username) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "user/" + username;

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }

  update(username, data) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "user/" + username;

      let payload = {
        user: {
          forename: data.forename,
          surname: data.surname,
          email: data.email,
        },
      };

      Fetch.run(url, "PUT", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  updatePassword(username, data) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "user/" + username + "/password";

      let payload = {
        password: {
          current: data.current,
          new: data.new,
          newVerify: data.newVerify,
        },
      };

      Fetch.run(url, "PUT", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new User();
