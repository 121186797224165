import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    orderData: "",
  },
  mutations: {
    setOrderData(state, orderData) {
      state.orderData = orderData;
    },
  },
  actions: {
    setOrderData(context, orderData) {
      context.commit("setOrderData", orderData);
    },
  },
  modules: {},
  getters: {
    orderData: state => {
      return state.orderData;
    },
  },
});
