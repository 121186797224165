import Auth from "@/store/auth";

class Fetch {
  run(url, method = "GET", data = null, accept_language = "de") {
    return new Promise(resolve => {
      let bearer = Auth.getters.jwt ? Auth.getters.jwt : Auth.getters.shopJwt;

      let options = {
        method: typeof method !== "string" ? "GET" : method,
        headers: {
          Authorization: "Bearer " + bearer,
          "Content-Type": "application/json",
          "Accept-Language": accept_language,
        },
      };

      if (typeof data === "object" && method !== "GET") {
        options.body = JSON.stringify(data);
      }

      fetch(url, options).then(response => {
        if (response.status === 200) {
          response.json().then(json => {
            resolve(json);
          });
        } else if (response.status === 400) {
          console.error("Bad Request");
          response.json().then(json => {
            resolve(json);
          });
        } else if (response.status === 401) {
          console.log("Logout by Fetch 401");
          Auth.dispatch("setJwt", "").catch(err => {
            console.error(err);
          });
          resolve([]);
        } else if (response.status === 404) {
          console.error("Not Found");
          resolve([]);
        } else if (response.status === 500) {
          response.json().then(json => {
            resolve(json);
          });
        }
      });
    });
  }
}

export default new Fetch();
