<template>
  <!-- Page Content -->
  <div class="hero-static d-flex align-items-center">
    <div class="w-100">
      <!-- Header -->
      <div
        v-if="routeName == 'customerOrderLogin'"
        class="font-size-sm text-center text-muted py-3"
      >
        {{ $t("login.order") }}
      </div>
      <!-- END Header -->
      <!-- Sign In Section -->
      <div class="bg-white">
        <div class="content content-full">
          <b-row class="justify-content-center">
            <b-col md="8" lg="6" xl="4" class="py-4">
              <!-- Invalid Login -->

              <b-alert variant="danger" show dismissible v-if="error">
                <p class="mb-0">
                  {{ $t("login.form.error.auth") }}
                </p>
              </b-alert>

              <!-- Header -->
              <div class="text-center">
                <p class="mb-2">
                  <i class="fa fa-2x fa-gem text-lightblue"></i>
                </p>
                <h1 class="h4 mb-1">
                  {{ $t("title") }}
                </h1>
              </div>
              <!-- END Header -->

              <!-- Sign In Form -->
              <b-form @submit.stop.prevent="onSubmit">
                <div class="py-3">
                  <div class="form-group">
                    <b-form-input
                      size="lg"
                      class="form-control-alt"
                      id="username"
                      name="username"
                      :placeholder="$t('login.form.placeholder.username')"
                      v-model="$v.form.username.$model"
                      :state="!$v.form.username.$error && null"
                      aria-describedby="username-feedback"
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <b-form-input
                      type="password"
                      size="lg"
                      class="form-control-alt"
                      id="password"
                      name="password"
                      :placeholder="$t('login.form.placeholder.password')"
                      v-model="$v.form.password.$model"
                      :state="!$v.form.password.$error && null"
                      aria-describedby="password-feedback"
                    ></b-form-input>
                  </div>
                </div>
                <b-row class="form-group row justify-content-center mb-0">
                  <b-col md="6" xl="5">
                    <b-button type="submit" variant="primary" block>
                      <i class="fa fa-fw fa-sign-in-alt mr-1"></i>
                      {{ $t("login.form.button") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!-- END Sign In Form -->
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- END Sign In Section -->

      <!-- Footer -->
      <div class="font-size-sm text-center text-muted py-3">
        <strong>egf B2B</strong> &copy; egf - Eduard G. Fidel GmbH
      </div>
      <!-- END Footer -->
    </div>
  </div>
  <!-- END Page Content -->
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import AuthStore from "@/store/auth";

import Login from "@/assets/js/Auth.js";

export default {
  name: "LoginComponent",
  props: {
    routeName: String,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      error: false,
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  mounted() {
    let username = this.$cookies.get("username");

    if (username != "") {
      this.form.username = username;
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      Login.login(this.form.username, this.form.password).then(result => {
        if (result == false) {
          this.error = true;
          return;
        }

        if (typeof result === "object") {
          if ("auth" in result) {
            if (result["auth"] == "false") {
              this.error = true;
              return false;
            }
          }
        }

        if (typeof result === "object") {
          this.$cookies.set("username", this.form.username);
        }

        /* Prüfung ob die ShopId aus der aktuellen Route zum User passt.
          Falls nicht, wird die Shop-ID auf die Default Shop ID des Users geändert.  
        */

        if (result !== false) {
          let routeShopId = this.$route.params["shopid"];
          let shopListByUser = AuthStore.getters.shopList;

          let isShopIdInUserList = false;

          for (let idx in shopListByUser) {
            if (routeShopId == shopListByUser[idx].shop_id) {
              isShopIdInUserList = true;
            }
          }

          if (isShopIdInUserList === false) {
            let defaultShopId = AuthStore.getters.shopId;

            this.$router
              .replace({
                name: "Home",
                params: {
                  shopid: defaultShopId,
                },
              })
              .catch(() => {});
          }
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
