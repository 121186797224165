import Fetch from "@/assets/js/Fetch";

class ManufacturesOrdersList {
  getListActive(year_filter) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "manufacture/1000/orders?status=active&year=" +
        year_filter;

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }

  getListArchive(year_filter) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "manufacture/1000/orders?status=archived&year=" +
        year_filter;

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }
}

export default new ManufacturesOrdersList();
