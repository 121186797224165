<template>
  <form id="shoppingcart-form" @submit.prevent="order" autocomplete="off">
    <!-- Page Content -->
    <div class="content">
      <!-- Hero -->
      <base-block rounded content-full>
        <div class="py-1 text-center">
          <h1 class="mb-0 my-0 py-0">
            {{ $t("customer.shoppingcart.title") }}
            <!-- #{{ cartId }} -->
          </h1>
          <small class="text-secondary">
            {{ shopName }}
          </small>
        </div>
      </base-block>
      <!-- END Hero -->

      <!-- Shopping Cart Items -->
      <b-row v-if="isShoppingCartReady">
        <b-col sm="6" v-for="(item, itemKey) in items" :key="itemKey">
          <base-block rounded>
            <b-row>
              <b-col sm="12">
                <div class="image-wrapper">
                  <b-img
                    fluid
                    rounded
                    class="py-1 px-4"
                    style="border: 1px solid #ededed"
                    :src="item.details.loadedImage"
                  />
                  <b-img
                    center
                    v-if="item.details.imageLoader"
                    src="/img/loading.svg"
                    alt=""
                    width="100"
                    height="100"
                    class="image-loader"
                  />
                </div>
                <base-block
                  :title="
                    $t('customer.shoppingcart.article') + ' ' + (itemKey + 1)
                  "
                  header-class="bg-primary"
                  class="pt-1 h"
                  style="height: auto;"
                  :style="{ height: layout.articleBlockHeight }"
                  themed
                  ref="article"
                  data="article"
                >
                  <b-table-simple
                    responsive
                    borderless
                    small
                    table-class="table-vcenter"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.description") }}:
                        </b-td>
                        <b-td>
                          {{ item.name }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.size") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.size }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.configuration") }}:
                        </b-td>
                        <b-td>
                          {{ item.origin_id }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.itemnumber") }}:
                        </b-td>
                        <b-td>
                          {{ item.itemnumber }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </base-block>

                <base-block
                  :title="$t('customer.shoppingcart.engraving.title')"
                  header-class="bg-primary"
                  class="pt-1 h"
                  style="height: auto;"
                  :style="{ height: layout.engravingBlockHeight }"
                  themed
                  data="engraving"
                  ref="engraving"
                >
                  <b-table-simple
                    responsive
                    borderless
                    small
                    table-class="table-vcenter"
                  >
                    <template v-if="item.engraving.length < 1">
                      <b-tbody>
                        <b-tr>
                          <b-td class="font-w600" style="width: 35%">
                            {{ $t("customer.shoppingcart.engraving.title") }}:
                          </b-td>
                          <b-td>
                            {{ $t("customer.shoppingcart.engraving.none") }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </template>
                    <template v-else>
                      <b-tbody
                        v-for="(engraving, i) in item.engraving"
                        :key="'engraving_id_' + i"
                      >
                        <b-tr>
                          <b-td colspan="2">
                            <hr v-if="i > 0" />
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="font-w600" style="width: 35%">
                            {{ $t("customer.shoppingcart.engraving.position") }}
                          </b-td>
                          <b-td>
                            {{ engraving.side_text }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="font-w600">
                            {{ $t("customer.shoppingcart.engraving.text") }}:
                          </b-td>
                          <b-td :class="'engraving-font-' + engraving.font_id">
                            {{ engraving.text }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="font-w600">
                            {{ $t("customer.shoppingcart.engraving.font") }}:
                          </b-td>
                          <b-td :class="'engraving-font-' + engraving.font_id">
                            {{ engraving.font_id }}
                          </b-td>
                        </b-tr>
                        <template v-if="engraving.individual_id && 1 == 17">
                          <b-tr>
                            <b-td class="font-w600">
                              {{
                                $t(
                                  "customer.shoppingcart.engraving.individual",
                                )
                              }}:
                            </b-td>
                            <b-td>
                              {{ engraving.individual_id }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="font-w600" colspan="2">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4
  //8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
                              />
                            </b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </template>
                  </b-table-simple>
                </base-block>

                <base-block
                  :title="$t('customer.shoppingcart.price.title')"
                  header-class="bg-primary"
                  class="pt-1"
                  themed
                >
                  <b-table-simple
                    responsive
                    borderless
                    small
                    table-class="table-vcenter"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.price.ring") }}:
                        </b-td>
                        <b-td>
                          {{ item.price.ring }},- {{ price.currency }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.price.stone") }}:
                        </b-td>
                        <b-td>
                          {{ item.price.stone }},- {{ price.currency }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.price.engraving") }}:
                        </b-td>
                        <b-td>
                          {{ item.price.engraving }},- {{ price.currency }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.price.total") }}:
                        </b-td>
                        <b-td>
                          {{ item.price.total }},- {{ price.currency }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </base-block>

                <base-block
                  :title="$t('customer.shoppingcart.description')"
                  header-class="bg-primary"
                  class="pt-1 h"
                  style="height: auto;"
                  :style="{ height: layout.descriptionBlockHeight }"
                  themed
                  ref="description"
                >
                  {{ item.details.description }}
                </base-block>

                <base-block
                  :title="$t('customer.shoppingcart.details.title')"
                  header-class="bg-primary"
                  class="pt-1"
                  style="height: auto;"
                  :style="{ height: layout.detailsBlockHeight }"
                  themed
                  ref="details"
                >
                  <template #options>
                    <button
                      type="button"
                      class="btn-block-option"
                      @click="toggleItemDetails()"
                    >
                      <i
                        v-if="layout.showItemDetails === false"
                        class="fa fa-angle-down"
                      ></i>
                      <i
                        v-if="layout.showItemDetails === true"
                        class="fa fa-angle-up"
                      ></i>
                    </button>
                  </template>

                  <b-table-simple
                    responsive
                    borderless
                    small
                    table-class="table-vcenter"
                    v-if="layout.showItemDetails === true"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.details.brand") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.brand }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.details.metal") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.metal }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.details.finish") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.finish }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.details.profile") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.profile }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.details.width") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.width }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600" style="width: 35%">
                          {{ $t("customer.shoppingcart.details.height") }}:
                        </b-td>
                        <b-td>
                          {{ item.details.details.height }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="font-w600">
                          {{ $t("customer.shoppingcart.details.grooves") }}:
                        </b-td>
                        <b-td>
                          <span
                            v-for="(grooves, i) in item.details.details.grooves"
                            :key="i"
                            >{{ grooves }}<br
                          /></span>
                        </b-td>
                      </b-tr>
                      <template
                        v-for="(stone, i) in item.details.details.stones"
                      >
                        <b-tr :key="i">
                          <b-td class="font-w600">
                            {{ $t("customer.shoppingcart.details.stones") }}:
                          </b-td>
                          <b-td> &nbsp; </b-td>
                        </b-tr>
                        <b-tr :key="i + '_setting'">
                          <b-td class="font-w600">
                            {{
                              $t("customer.shoppingcart.details.stoneSetting")
                            }}:
                          </b-td>
                          <b-td> {{ stone.setting }} </b-td>
                        </b-tr>
                        <b-tr :key="i + '_quality'">
                          <b-td class="font-w600">
                            {{
                              $t("customer.shoppingcart.details.stoneQuality")
                            }}:
                          </b-td>
                          <b-td> {{ stone.quality }} </b-td>
                        </b-tr>
                        <b-tr :key="i + '_carat'">
                          <b-td class="font-w600">
                            {{
                              $t("customer.shoppingcart.details.stoneCarat")
                            }}:
                          </b-td>
                          <b-td> {{ stone.carat }} </b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </base-block>

                <base-block
                  :title="$t('customer.shoppingcart.manufacturingNotes.title')"
                  header-class="bg-primary"
                  class="pt-1"
                  style="height: auto;"
                  :style="{ height: layout.manufacturingNotesBlockHeight }"
                  themed
                  ref="manufacturingNotes"
                  v-if="layout.showItemDetails === true"
                >
                  <b-form-textarea
                    rows="4"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.manufacturingNotes.articleNotes',
                      )
                    "
                    v-model="item.manufacturingNotes"
                  ></b-form-textarea>

                  <div
                    class="text-right"
                    v-if="itemKey == 0 && numberOfItems > 1"
                  >
                    <b-button
                      @click="copyManufacturingNotes()"
                      size="sm"
                      variant="alt-primary"
                      class="btn-rounded mt-1 text-right"
                    >
                      {{
                        $t("customer.shoppingcart.manufacturingNotes.copyText")
                      }}
                      <i class="fa fa-angle-double-right mr-1"></i>
                    </b-button>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
      <!-- END Shopping Cart Items-->

      <!-- Total Price -->
      <b-row v-if="items">
        <b-col sm="12">
          <base-block rounded>
            <h3>
              {{ $t("customer.shoppingcart.priceSummary.totalPrice") }}:
              {{ price.total }},- {{ price.currency }}
            </h3>
            <p>({{ $t("customer.shoppingcart.priceSummary.priceWithTax") }})</p>
          </base-block>
        </b-col>
      </b-row>
      <!-- END Total Price -->

      <!-- Shopping Cart Items -->
      <b-row v-if="items">
        <b-col sm="12">
          <!-- Necessary Order Forms -->
          <b-row>
            <b-col sm="12">
              <h2 class="text-muted text-center">
                {{ $t("customer.shoppingcart.orderFormData.title") }}
              </h2>
            </b-col>
            <b-col md="6">
              <base-block rounded>
                <b-form-group label-for="customerPickupDate">
                  <template #label>
                    {{
                      $t(
                        "customer.shoppingcart.orderFormData.customerPickupDate",
                      )
                    }}
                    <span class="text-danger">*</span>
                  </template>
                  <flat-pickr
                    id="customerPickupDate"
                    class="form-control bg-white"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.orderFormData.customerPickupDatePlaceholder',
                      )
                    "
                    v-model="customerPickupDate"
                    :config="flatPickrConfig"
                  ></flat-pickr>
                </b-form-group>
              </base-block>
            </b-col>
            <b-col md="6">
              <base-block rounded>
                <b-form-group label-for="commission">
                  <template #label>
                    {{ $t("customer.shoppingcart.orderFormData.commission") }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model="commission"
                    id="commission"
                    class="form-control bg-white"
                    type="text"
                    name="commission"
                    maxlength="50"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.orderFormData.commissionPlaceholder',
                      )
                    "
                  ></b-form-input>
                </b-form-group>
              </base-block>
            </b-col>
          </b-row>
          <!-- END Necessary Order Forms -->
          <!-- Optional Order Forms -->
          <b-row>
            <b-col md="12" v-if="!layout.optionalOrderDataForm">
              <p class="font-size-sm text-muted text-center">
                <b-button
                  size="sm"
                  variant="alt-info"
                  class="btn-rounded p-3 m-2"
                  @click="
                    layout.optionalOrderDataForm = !layout.optionalOrderDataForm
                  "
                >
                  {{ $t("customer.shoppingcart.optionalOrderFormData.title") }}
                  <i class="fa fa-angle-down"></i>
                </b-button>
              </p>
            </b-col>
            <b-col md="6" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group
                  :label="
                    $t(
                      'customer.shoppingcart.optionalOrderFormData.weddingDate',
                    )
                  "
                  label-for="weddingDate"
                >
                  <flat-pickr
                    id="weddingDate"
                    class="form-control bg-white"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.optionalOrderFormData.weddingDatePlaceholder',
                      )
                    "
                    v-model="weddingDate"
                    :config="flatPickrConfig"
                  ></flat-pickr>
                </b-form-group>
              </base-block>
            </b-col>
            <b-col md="6" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group label-for="salesperson">
                  <template #label>
                    {{
                      $t(
                        "customer.shoppingcart.optionalOrderFormData.salesperson",
                      )
                    }}
                  </template>
                  <b-form-input
                    v-model="salesperson"
                    id="salesperson"
                    class="form-control bg-white"
                    type="text"
                    name="salesperson"
                    maxlength="25"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.optionalOrderFormData.salespersonPlaceholder',
                      )
                    "
                  ></b-form-input>
                </b-form-group>
              </base-block>
            </b-col>
            <b-col md="6" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group
                  :label="
                    $t('customer.shoppingcart.optionalOrderFormData.deposit')
                  "
                  label-class="font-w600"
                >
                  <div class="form-group mt-2">
                    <b-input-group :prepend="price.currency">
                      <b-form-input
                        v-model="deposit"
                        id="deposit"
                        type="number"
                        name="deposit"
                        :placeholder="
                          $t(
                            'customer.shoppingcart.optionalOrderFormData.depositPlaceholder',
                          )
                        "
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </b-form-group>
              </base-block>
            </b-col>
            <!--
            <b-col md="6" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group
                  label="Profilmuster (Funktion folgt in Kürze)"
                  label-class="font-w600"
                >
                  <b-form-radio-group
                    id="sample-radio-group"
                    v-model="sample"
                    :options="sampleOptions"
                    name="sample-radio-options"
                    stacked
                    disabled
                  ></b-form-radio-group>
                </b-form-group>
                
              </base-block>
            </b-col>
            -->
            <b-col md="12" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group
                  :label="
                    $t('customer.shoppingcart.optionalOrderFormData.notes')
                  "
                  label-class="font-w600"
                >
                  <b-form-textarea
                    id="notes"
                    rows="4"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.optionalOrderFormData.notesPlaceholder',
                      )
                    "
                    v-model="notes"
                  ></b-form-textarea>
                </b-form-group>
              </base-block>
            </b-col>

            <b-col md="12" v-if="layout.optionalOrderDataForm">
              <base-block rounded>
                <b-form-group
                  :label="
                    $t(
                      'customer.shoppingcart.optionalOrderFormData.notesInternal',
                    )
                  "
                  label-class="font-w600"
                >
                  <b-form-textarea
                    id="notesInternal"
                    rows="4"
                    :placeholder="
                      $t(
                        'customer.shoppingcart.optionalOrderFormData.notesInternalPlaceholder',
                      )
                    "
                    v-model="notesInternal"
                  ></b-form-textarea>
                </b-form-group>
              </base-block>
            </b-col>

            <b-col md="12" class="text-center">
              <b-button
                @click="order()"
                size="lg"
                variant="alt-success"
                class="btn-rounded px-6 mr-2 mb-4"
              >
                <i class="si si-rocket mr-1"></i>
                {{ $t("customer.shoppingcart.order") }}
              </b-button>
            </b-col>
          </b-row>
          <!-- END Order Forms -->
        </b-col>
      </b-row>
      <!-- END Shopping Cart Items-->
    </div>

    <!-- 404 Shoppingcart Not Found -->
    <b-row v-if="!isShoppingCartReady">
      <b-col sm="12">
        <base-block rounded content-full>
          <div class="py-1 text-center">
            <h3 class="mb-0 my-0 py-0">
              {{ $t("customer.shoppingcart.error.notFound") }}
            </h3>

            <b-button
              :href="`/${this.shopId}/customer/shoppingcartlist/`"
              size="lg"
              variant="alt-primary"
              class="btn-rounded my-4 px-6 mr-2 text-white"
            >
              <i class="fa fa-shopping-cart mr-1"></i>
              {{ $t("customer.shoppingcart.error.buttonShoppingcarts") }}
            </b-button>
          </div>
        </base-block>
      </b-col>
    </b-row>
    <!-- END 404 Shoppingcart Not Found -->

    <!-- END Page Content -->
  </form>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import AuthStore from "@/store/auth";
import ShoppingcartStore from "@/store/shoppingcart";

import Loading from "@/store/loading";
import ShoppingCart from "@/assets/js/ShoppingCart";
import Engraving from "@/assets/js/Engraving";
import Layout from "@/assets/js/Layout";

export default {
  name: "CustomerShoppingCart",
  components: {
    flatPickr,
  },
  data() {
    return {
      // Get more form https://flatpickr.js.org/options/
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d", // Date Format displayed in the input field
        altInput: true,
        dateFormat: "Y-m-d", // Date Format used for submitting
        locale: English,
      },
      layout: {
        showItemDetails: false,
        optionalOrderDataForm: false,
        articleBlockHeightInt: null,
        articleBlockHeight: null,
        engravingBlockHeightInt: null,
        engravingBlockHeight: null,
        descriptionBlockHeightInt: null,
        descriptionBlockHeight: null,
        detailsBlockHeightInt: null,
        detailsBlockHeight: null,
        manufacturingNotesBlockHeightInt: null,
        manufacturingNotesBlockHeight: null,
      },
      isShoppingCartReady: false,
      price: {
        total: 0,
        currency: "EUR",
        country: "de",
      },
      items: [
        {
          id: 0,
          itemnumber: "Loading...",
          manufacturingNotes: "",
          price: {
            total: 0,
            ring: 0,
            stone: 0,
            engraving: 0,
          },
          details: {
            title: "Loading...",
            description: "Loading...",
            size: "Loading...",
            image: {
              0: "/img/sample/item-loading.jpg",
            },
            details: {
              brand: "",
              profile: "",
              width: "",
              height: "",
              finish: "",
              metal: "",
              grooves: {},
              stones: {},
            },
          },
          engraving: {
            0: {
              engraving_id: "---",
              type: "---",
              side: "---",
              font_id: "---",
              text: "---",
            },
          },
        },
        {
          id: 1,
          itemnumber: "Loading...",
          manufacturingNotes: "",
          price: {
            total: 0,
            ring: 0,
            stone: 0,
            engraving: 0,
          },
          details: {
            title: "Loading...",
            description: "Loading...",
            size: "Loading...",
            image: {
              0: "/img/sample/item-loading.jpg",
            },
            details: {
              brand: "",
              profile: "",
              width: "",
              height: "",
              finish: "",
              metal: "",
              grooves: {},
              stones: {},
            },
          },
          engraving: {
            0: {
              engraving_id: "---",
              type: "---",
              side: "---",
              font_id: "---",
              text: "---",
            },
          },
        },
      ],
      shopId: "",
      shopName: "",
      cartId: "",
      cartTimestamp: "",
      cartDateTime: "",
      deliveryDate: "",
      commission: "",
      customerPickupDate: "",
      weddingDate: "",
      salesperson: "",
      notes: "",
      notesInternal: "",
      deposit: "",
      sample: "sampleNo",
      sampleOptions: [
        { text: "Kein Profilmuster", value: "sampleNo" },
        { text: "Standard Profilmuster", value: "sampleStandard" },
        { text: "Profilmuster mit Extras", value: "sampleExtra" },
      ],
    };
  },
  mounted() {
    var cartTimestamp = this.$route.params.carttimestamp;
    var shopId = this.$route.params.shopid;

    this.shopId = shopId;
    this.cartTimestamp = cartTimestamp;

    Loading.dispatch("reduce", 1);

    this.setCustomerPickupDate();
    this.showList(cartTimestamp, shopId);
  },
  methods: {
    async loadData(cartTimestamp, shopId) {
      let shoppingcartData = await ShoppingCart.get(cartTimestamp, shopId);

      if (shoppingcartData === false) {
        // Versuche 5 Mal, auf das Laden des Einkaufswagens zu warten
        for (let i = 0; i < 5; i++) {
          console.log("Shoppingcart not found. Try again in 2 second.");
          await new Promise(resolve => setTimeout(resolve, 2000));
          shoppingcartData = await ShoppingCart.get(cartTimestamp, shopId);
          if (shoppingcartData !== false) {
            return shoppingcartData;
          }
        }
      }

      return shoppingcartData;
    },
    async showList(cartTimestamp, shopId) {
      Loading.dispatch("increase", 1);

      try {
        const data = await this.loadData(cartTimestamp, shopId);

        // if shoppingcart not found after 5 tries
        if (data === false) {
          Loading.dispatch("reduce", 1);
          console.log("isShoppingCartReady ", this.isShoppingCartReady);
          console.log("shoppingcart not found after 5 tries ", data);
          return false;
        }

        /* change language and set language-cookie */
        if (data.shop_language !== this.$i18n.locale) {
          this.$i18n.locale = data.shop_language;
          this.$cookies.set("egfb2b-language", this.$i18n.locale);
        }

        this.cartId = data.cart_id;
        this.shopName = data.shop_name;
        this.items = data.items;
        this.cartDateTime = data.datetime;
        this.price.total = data.totalSalesPrice;
        this.price.country = data.country;

        if (data.currency) {
          this.price.currency = data.currency.toUpperCase();
        }

        for (var key in this.items) {
          let item = this.items[key];

          let originalImage = item.details.image[0];

          item.details.loadedImage = "/img/sample/item-loading.jpg";
          item.details.imageLoader = true;

          const checkImageAvailability = async (item, scope, attempt = 1) => {
            if (attempt > 20) {
              console.error(
                "Maximale Versuche erreicht, Bild konnte nicht geladen werden.",
              );
              item.details.loadedImage = "/img/sample/item-error.jpg";
              item.details.imageLoader = false;
              scope.$forceUpdate();
              return;
            }

            try {
              const response = await fetch(originalImage, { method: "HEAD" });
              if (response.status === 200) {
                item.details.loadedImage = originalImage;
                item.details.imageLoader = false;
                scope.$forceUpdate();
              } else {
                setTimeout(
                  () => checkImageAvailability(item, scope, attempt + 1),
                  2000,
                );
              }
            } catch (error) {
              console.error("Fehler beim Überprüfen des Bildes:", error);
              setTimeout(
                () => checkImageAvailability(item, scope, attempt + 1),
                2000,
              );
            }
          };

          checkImageAvailability(item, this);

          for (var engravingKey in item["engraving"]) {
            let fontId = item["engraving"][engravingKey]["font_id"];
            Engraving.loadFont(fontId);
          }
        }

        this.isShoppingCartReady = true;
        await Loading.dispatch("reduce", 1);

        await this.matchArticleHeight();
        await this.matchEngravingHeight();
        await this.matchDescriptionHeight();
      } catch (err) {
        // Es gab einen Fehler
        console.log("Shoppingcart not found.", err);
        Loading.dispatch("reduce", 1);
      }
    },

    setCustomerPickupDate() {
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 28);
      let proposalCustomerPickupDate = dateObj.toISOString().slice(0, 10);
      this.customerPickupDate = proposalCustomerPickupDate;
    },
    matchArticleHeight() {
      const articleBlockHeight = Layout.calculateMaxHeight(
        this.$refs.article,
        "articleBlockHeight",
      );

      this.layout.articleBlockHeightInt = articleBlockHeight;
      this.layout.articleBlockHeight = articleBlockHeight + "px";
    },
    matchEngravingHeight() {
      const maxHeight = Layout.calculateMaxHeight(
        this.$refs.engraving,
        "engravingBlockHeight",
      );

      this.layout.engravingBlockHeightInt = maxHeight;
      this.layout.engravingBlockHeight = maxHeight + "px";
    },

    matchDescriptionHeight() {
      const maxHeight = Layout.calculateMaxHeight(
        this.$refs.description,
        "descriptionBlockHeightInt",
      );

      this.layout.descriptionBlockHeightInt = maxHeight;
      this.layout.descriptionBlockHeight = maxHeight + "px";
    },

    matchDetailsHeight() {
      const maxHeight = Layout.calculateMaxHeight(
        this.$refs.details,
        "detailsBlockHeightInt",
      );
      this.layout.detailsBlockHeightInt = maxHeight;
      this.layout.detailsBlockHeight = maxHeight + "px";
    },

    matchManufacuringNotesHeight() {
      const maxHeight = Layout.calculateMaxHeight(
        this.$refs.manufacturingNotes,
        "manufacturingNotesBlockHeightInt",
      );
      this.layout.manufacturingNotesBlockHeightInt = maxHeight;
      this.layout.manufacturingNotesBlockHeight = maxHeight + "px";
    },
    toggleItemDetails() {
      this.layout.showItemDetails = !this.layout.showItemDetails;

      if (this.layout.showItemDetails === false) {
        this.layout.detailsBlockHeight = "auto";
        this.layout.detailsBlockHeightInt = null;
      } else {
        this.$nextTick(() => {
          this.matchDetailsHeight();
          this.matchManufacuringNotesHeight();
        });
      }
    },
    copyManufacturingNotes() {
      let numberOfItems = this.items.length;
      if (numberOfItems > 1) {
        let manufacturingNotes = this.items[0].manufacturingNotes;
        let i = 1;
        for (i; i < numberOfItems; i++) {
          this.items[i].manufacturingNotes = manufacturingNotes;
        }
        this.$forceUpdate();
      }
    },
    validateInput() {
      let customerPickupDate = this.customerPickupDate;

      if (customerPickupDate === "") {
        // "Der Kunden Abholtermin muss ausgewählt werden.."
        alert(
          this.$t(
            "customer.shoppingcart.orderFormData.customerPickupDateEmpty",
          ),
        );
        return false;
      }

      let parts = customerPickupDate.split("-");

      let day = parts[2];
      let month = parts[1];
      let year = parts[0];

      let dateObj = new Date(year, +month - 1, day);
      const isValidDate = Boolean(+dateObj) && dateObj.getDate() == day;

      if (isValidDate === false) {
        // Der Kunden Abholtermin ist nicht gültig
        alert(
          this.$t(
            "customer.shoppingcart.orderFormData.customerPickupDateInvalid",
          ),
        );
        return false;
      } else if (isValidDate === true) {
        let today = new Date();
        if (dateObj.getTime() <= today.getTime()) {
          // Der Kunden Abholtermin muss in der Zukunft liegen
          alert(
            this.$t(
              "customer.shoppingcart.orderFormData.customerPickupDateInFuture",
            ),
          );
          return false;
        }
      }

      // Alert: Die Komission muss ausgefüllt sein.
      if (this.commission.length === "" || this.commission.length < 3) {
        alert(this.$t("customer.shoppingcart.orderFormData.commissionEmpty"));
        return false;
      }

      return true;
    },
    order() {
      // Show Loader
      Loading.dispatch("increase", 1);

      // validate important form data
      if (this.validateInput() === false) {
        Loading.dispatch("reduce", 1);
        return false;
      }

      // collect form data for order
      let orderData = {
        shopId: this.shopId,
        cartTimestamp: this.cartTimestamp,
        items: this.items,
        commission: this.commission,
        customerPickupDate: this.customerPickupDate,
        weddingDate: this.weddingDate,
        salesperson: this.salesperson,
        notes: this.notes,
        notesInternal: this.notesInternal,
        currency: this.price.currency,
        deposit: this.deposit,
        sample: this.sample,
      };

      // save form data in Store
      ShoppingcartStore.dispatch("setOrderData", orderData);

      if (AuthStore.getters.jwt) {
        // send order directly to backend
        ShoppingCart.createOrder(this.shopId, orderData).then(result => {
          if (result.status == "true") {
            let confirmationUrl =
              "/" +
              result.shop_id +
              "/customer/orderconfirmation/" +
              result.order_id;
            this.$router.push(confirmationUrl);
          } else {
            alert(this.$t("order.error.title"));
          }
        });
      } else {
        let loginUrl = "/" + this.shopId + "/customer/orderlogin/";
        this.$router.push(loginUrl);
      }
    },
    reinitializeFlatpickr() {
      if (this.$refs.flatPickr) {
        const flatpickrInstance = this.$refs.flatPickr.flatpickr;
        if (flatpickrInstance) {
          flatpickrInstance.destroy();
          this.$refs.flatPickr.flatpickr(this.flatPickrConfig);
        }
      }
    },
  },
  watch: {
    "$i18n.locale": {
      handler(newLocale) {
        const locales = {
          de: German,
          nl: Dutch,
          fr: French,
          ja: Japanese,
          ko: Korean,
          en: English,
        };
        this.flatPickrConfig.locale = locales[newLocale] || English;

        switch (newLocale) {
          case "de":
            this.flatPickrConfig.altFormat = "d.m.Y";
            break;
          case "nl":
            this.flatPickrConfig.altFormat = "d-m-Y";
            break;
          case "fr":
            this.flatPickrConfig.altFormat = "d/m/Y";
            break;
          case "ja":
            this.flatPickrConfig.altFormat = "Y/m/d";
            break;
          case "ko":
            this.flatPickrConfig.altFormat = "Y.m.d";
            break;
          case "en":
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
          default:
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
        }

        this.$nextTick(() => {
          this.reinitializeFlatpickr();
        });
      },
      immediate: true,
    },
  },
  computed: {
    numberOfItems: function() {
      return this.items.length;
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";

.image-wrapper {
  position: relative;
  height: auto;
}

.image-loader {
  position: absolute;
  left: 38%;
  top: 42%;
  width: 25%;
  height: 25%;
  border: none;
}
</style>
