class Engraving {
  loadFont(fontId) {
    const Font = new FontFace(fontId, "url(/fonts/" + fontId + ".woff)");

    Font.load()
      .then(function(loadedFont) {
        document.fonts.add(loadedFont);
      })
      .catch(function(error) {
        console.log("Failed to load font: " + error);
      });
  }
}

export default new Engraving();
