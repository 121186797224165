import Fetch from "@/assets/js/Fetch";
import i18n from "../../i18n";

class Order {
  get(shopId, orderId) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/order/" + orderId;

      let current_language = i18n.locale;

      Fetch.run(url, "GET", null, current_language)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
          resolve(error);
        });
    });
  }

  update(shopId, orderId, key, value, key2 = null, value2 = null) {
    return new Promise(resolve => {
      const url =
        process.env.VUE_APP_API + "shop/" + shopId + "/order/" + orderId;

      const payload = {
        order: {
          key: key,
          value: value,
        },
      };

      if (key2 != null && value2 != null) {
        payload.order.key2 = key2;
        payload.order.value2 = value2;
      }

      Fetch.run(url, "PUT", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  updateArticles(shopId, orderId, containerId) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/order/" + orderId;

      let payload = {
        container: {
          container_id: containerId,
        },
      };

      Fetch.run(url, "PUT", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  setStatus(shopId, orderId, type, value) {
    return new Promise((resolve, reject) => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/order/" + orderId;

      let payload = {
        status: {
          type: type,
          value: value,
        },
      };

      Fetch.run(url, "PUT", payload).then(data => {
        if (data.ok == true) {
          resolve(data);
        } else if (data.ok == false) {
          reject(data);
        }
      });
    });
  }

  exists(shopId, orderId) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "shop/" +
        shopId +
        "/order/" +
        orderId +
        "/exists";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }

  createServiceOrder(shopId, formData) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "shop/" + shopId + "/order/service";

      Fetch.run(url, "POST", formData)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  createSampleOrder(shopId, formData) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "shop/" + shopId + "/order/sample";

      Fetch.run(url, "POST", formData)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new Order();
