var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-block',{attrs:{"title":_vm.$t('order.actions.title'),"header-class":"bg-primary","themed":""}},[(_vm.division == 'shop')?_c('section',[_c('p',[_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 1},on:{"click":function($event){return _vm.setShopStatus('sent_to_supplier', 1)}}},[_c('i',{staticClass:"far fa-paper-plane mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.actions.sendToManufacture"))+" ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_shop == 0 || _vm.order.status.in_shop == 1},on:{"click":function($event){return _vm.setShopStatus('in_shop', 1)}}},[_c('i',{staticClass:"fa fa-house-user mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.actions.inShop"))+" ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.in_shop == 0 ||
            _vm.order.status.finished == 1 ||
            _vm.order.status.cancelled == 1},on:{"click":function($event){return _vm.setShopStatus('finished', 1)}}},[_c('i',{staticClass:"far fa-check-circle mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.actions.finishOrder"))+" ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 1 ||
            _vm.order.status.finished == 1 ||
            _vm.order.status.cancelled == 1},on:{"click":function($event){return _vm.setShopStatus('cancelled', 1)}}},[_c('i',{staticClass:"si si-close mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.actions.cancelOrder"))+" ")]),(_vm.order.status.cancelled == 1)?_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary"},on:{"click":function($event){return _vm.setShopStatus('cancelled', 0)}}},[_c('i',{staticClass:"si si-close mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.actions.reactivateOrder"))+" ")]):_vm._e()],1),(_vm.order.order_type == 'A')?_c('hr'):_vm._e(),(_vm.order.order_type == 'A')?_c('p',[(_vm.shopId && _vm.orderId && _vm.isServiceAllowedByCountry === true)?_c('router-link',{attrs:{"to":{
          name: 'OrderCreateService',
          params: { shopid: _vm.shopId, orderid: _vm.orderId },
        }}},[_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.production_finished == 0 ||
              _vm.order.status.cancelled == 1}},[_c('i',{staticClass:"fa fa-hammer mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.createServiceOrder"))+" ")])],1):_vm._e(),(_vm.shopId && _vm.orderId && _vm.isSampleAllowedByCountry === true)?_c('router-link',{attrs:{"to":{
          name: 'OrderCreateSample',
          params: { shopid: _vm.shopId, orderid: _vm.orderId },
        }}},[_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.finished == 1 || _vm.order.status.cancelled == 1}},[_c('i',{staticClass:"fa fa-ring mr-1"}),_vm._v(" "+_vm._s(_vm.$t("order.createSampleOrder"))+" ")])],1):_vm._e()],1):_vm._e()]):(_vm.division == 'manufacture')?_c('section',[_c('p',[_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 0 ||
            _vm.order.status.production_finished == 1},on:{"click":function($event){return _vm.setManufactureStatus('sent_to_supplier', 0)}}},[_c('i',{staticClass:"fas fa-window-close mr-1"}),_vm._v(" Auftrag komplett abbrechen ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 0 ||
            _vm.order.status.supplier_accepted == 1},on:{"click":function($event){return _vm.setManufactureStatus('supplier_accepted', 1)}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_vm._v(" Auftrag annehmen ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 0 ||
            _vm.order.status.in_production == 1},on:{"click":function($event){return _vm.setManufactureStatus('in_production', 1)}}},[_c('i',{staticClass:"fas fa-hammer mr-1"}),_vm._v(" in Produktion ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 0 ||
            _vm.order.status.production_finished == 1 ||
            _vm.order.status.in_production == 0},on:{"click":function($event){return _vm.setManufactureStatus('production_finished', 1)}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_vm._v(" Produktion abgeschlossen ")]),_c('b-button',{staticClass:"m-1",attrs:{"size":"md","variant":"outline-secondary","disabled":_vm.order.status.sent_to_supplier == 0 ||
            _vm.order.status.sent_to_shop == 1 ||
            _vm.order.status.production_finished == 0},on:{"click":function($event){return _vm.setManufactureStatus('sent_to_shop', 1)}}},[_c('i',{staticClass:"far fa-paper-plane mr-1"}),_vm._v(" an Shop versendet ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }