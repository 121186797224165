<template>
  <!-- Header -->
  <header id="page-header">
    <slot>
      <!-- Header Content -->
      <div class="content-header border-left border-white-op">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Toggle Sidebar -->
          <base-layout-modifier
            action="sidebarToggle"
            size="sm"
            variant="dual"
            class="mr-2 d-lg-none"
          >
            <i class="fa fa-fw fa-bars"></i>
          </base-layout-modifier>
          <!-- END Toggle Sidebar -->

          <!-- Toggle Mini Sidebar -->
          <!--
          <base-layout-modifier
            action="sidebarMiniToggle"
            size="sm"
            variant="dual"
            class="mr-2 d-none d-lg-inline-block"
          >
            <i class="fa fa-fw fa-ellipsis-v"></i>
          </base-layout-modifier>
          -->
          <!-- END Toggle Mini Sidebar -->

          <!-- Open Search Section (visible on smaller screens) -->
          <base-layout-modifier
            action="headerSearchOn"
            variant="dual"
            size="sm"
            class="d-sm-none"
          >
            <i class="fa fa-fw fa-search"></i>
          </base-layout-modifier>
          <!-- END Open Search Section -->

          <!-- Search Form (visible on larger screens) -->
          <b-form
            @submit.stop.prevent="submitSearch"
            class="d-none d-sm-inline-block"
          >
            <b-input-group size="sm">
              <b-form-input
                :placeholder="$t('header.searchPlaceholder')"
                v-model="searchString"
                class="form-control-alt"
              ></b-form-input>
              <b-input-group-append>
                <span
                  class="input-group-text bg-body border-0"
                  @click="submitSearch"
                >
                  <i class="fa fa-fw fa-search"></i>
                </span>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <!-- END Search Form -->
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <!-- User Dropdown -->
          <b-dropdown
            size="sm"
            variant="primary"
            class="d-inline-block ml-2"
            menu-class="p-0 border-0 font-size-sm"
            right
            no-caret
          >
            <template #button-content>
              <img
                class="rounded-circle"
                src="/img/avatars/avatar10.jpg"
                alt="Header Avatar"
                style="width: 21px;"
              />
              <span class="d-none d-sm-inline-block ml-1">{{ name }}</span>
              <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
            </template>
            <li>
              <div class="p-2">
                <div v-if="hasManufactureAccess">
                  <h5 class="dropdown-header text-uppercase">
                    Manufaktur
                  </h5>
                  <router-link
                    class="dropdown-item d-flex align-items-center justify-content-between"
                    :to="{
                      name: 'manufactureHome',
                    }"
                  >
                    <span>Innendienst</span>
                    <i class="fa fa-ring"></i>
                  </router-link>
                </div>
                <h5 class="dropdown-header text-uppercase">
                  {{ $t("header.shops") }}
                </h5>
                <div v-for="(shopData, idx) in shopList" :key="idx">
                  <div
                    @click="changeShop(shopData.shop_id)"
                    class="dropdown-item d-flex align-items-center justify-content-between cursor-pointer"
                  >
                    <div>
                      {{ shopData.shop_id }}<br />
                      <span style="font-size: 0.7rem">{{ shopData.city }}</span>
                    </div>
                    <i
                      v-if="shopData.shop_id == currentShopId"
                      class="fa fa-house-user ml-1"
                    ></i>
                  </div>
                </div>
                <h5 class="dropdown-header text-uppercase">
                  {{ $t("header.user") }}
                </h5>
                <router-link
                  v-if="currentShopId > 0"
                  class="dropdown-item d-flex align-items-center justify-content-between"
                  :to="{
                    name: 'UserProfile',
                    params: { shopid: currentShopId },
                  }"
                >
                  <span>{{ $t("header.profile") }}</span>
                  <i class="fa fa-user-edit ml-1"></i>
                </router-link>
                <a
                  class="dropdown-item d-flex align-items-center justify-content-between cursor-pointer"
                  @click="logout"
                >
                  <span>{{ $t("header.logout") }}</span>
                  <i class="fa fa-lock ml-1"></i>
                </a>
              </div>
            </li>
          </b-dropdown>
          <!-- END User Dropdown -->
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->

      <!-- Header Loader -->
      <div
        id="page-header-loader"
        class="overlay-header bg-white"
        :class="{ show: $store.state.settings.headerLoader }"
      >
        <div class="content-header">
          <div class="w-100 text-center">
            <i class="fa fa-fw fa-circle-notch fa-spin"></i>
          </div>
        </div>
      </div>
      <!-- END Header Loader -->
    </slot>
  </header>
  <!-- END Header -->
</template>

<script>
import Vue from "vue";
import Auth from "@/store/auth";
import VueSweetalert2 from "vue-sweetalert2";

const vueSweetalert2Options = {
  buttonsStyling: false,
  customClass: {
    confirmButton: "btn btn-success m-1",
    cancelButton: "btn btn-danger m-1",
    input: "form-control",
  },
};

// Vue SweetAlert2, for more info and examples you can check out https://github.com/avil13/vue-sweetalert2
Vue.use(VueSweetalert2, vueSweetalert2Options);

export default {
  name: "BaseHeader",
  props: {
    classes: String,
  },
  data() {
    return {
      searchString: "",
      shopId: null,
    };
  },
  methods: {
    logout() {
      Auth.dispatch("setJwt", "");
      Auth.dispatch("setName", "");
      Auth.dispatch("setShopId", "");
      Auth.dispatch("setShopList", []);
    },
    changeShop(shopId) {
      this.$router.replace({
        name: "Home",
        params: {
          shopid: shopId,
        },
      });
      this.$router.go();
    },
    submitSearch() {
      if (this.isManufactureActive) {
        this.searchManufacture();
      } else {
        this.searchShop();
      }
    },
    searchShop() {
      const shopId = this.$route.params["shopid"];
      const searchString = this.searchString;
      const routeNameSearchResult = "OrdersSearchResult";

      this.searchString = "";

      this.$router
        .replace({
          name: routeNameSearchResult,
          params: {
            shopid: shopId,
          },
          query: {
            search: searchString,
          },
        })
        .catch(() => {});
    },
    searchManufacture() {
      const searchString = this.searchString;

      this.searchString = "";

      this.$router
        .replace({
          name: "manufactureSearchResult",
          query: {
            search: searchString,
          },
        })
        .catch(() => {});
    },
  },
  mounted() {
    document.addEventListener("keydown", this.eventHeaderSearch);
    this.$store.commit("header", { mode: "fixed" });
    this.$store.commit("headerStyle", { mode: "dark" });
  },
  destroyed() {
    document.removeEventListener("keydown", this.eventHeaderSearch);
  },
  computed: {
    name: function() {
      return Auth.getters.name;
    },
    currentShopId: function() {
      if (typeof this.$route.params["shopid"] !== "undefined") {
        return this.$route.params["shopid"];
      } else {
        return 0;
      }
    },
    shopList: function() {
      return Auth.getters.shopList;
    },
    userRole: function() {
      return Auth.getters.userRole;
    },
    hasManufactureAccess: function() {
      if (
        this.userRole == "admin" ||
        this.userRole == "backoffice" ||
        this.userRole == "sales"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isManufactureActive: function() {
      if (this.currentShopId == 0 && this.hasManufactureAccess) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
