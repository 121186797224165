import Vue from "vue";
import Vuex from "vuex";
import Store from "@/store/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    numberOfRequests: 0,
  },
  mutations: {
    increase(state, requests) {
      state.numberOfRequests += requests;

      if (state.numberOfRequests > 0) {
        Store.commit("pageLoader", { mode: "on" });
      }
    },
    reduce(state, requests) {
      state.numberOfRequests -= requests;

      if (state.numberOfRequests < 1) {
        Store.commit("pageLoader", { mode: "off" });
        state.numberOfRequests = 0;
      }
    },
  },
  actions: {
    increase(context, requests = 1) {
      context.commit("increase", requests);
    },
    reduce(context, requests = 1) {
      context.commit("reduce", requests);
    },
  },
});
