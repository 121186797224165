class Layout {
  calculateMaxHeight(refs) {
    if (Array.isArray(refs) && refs.length > 0) {
      let maxHeight = 0;

      for (const ref of refs) {
        if (ref.$el.clientHeight > maxHeight) {
          maxHeight = ref.$el.clientHeight;
        }
      }

      return maxHeight;
    }

    return 0;
  }
}

export default new Layout();
