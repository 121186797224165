import Fetch from "@/assets/js/Fetch";

class OrderConfirmation {
  openPdf(shopId, orderId, language, contentDisposition = "attachment") {
    if (contentDisposition !== "inline") {
      contentDisposition = "attachment";
    }

    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "shop/" +
        shopId +
        "/order/" +
        orderId +
        "/pdf";

      let payload = {
        type: "confirmation",
        language: language,
        content_disposition: contentDisposition,
      };

      Fetch.run(url, "POST", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new OrderConfirmation();
