var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('base-page-heading',{attrs:{"title":_vm.$t('orders.searchResult.title')},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('b-breadcrumb',{staticClass:"breadcrumb-alt"},[_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{
              name: 'manufactureHome',
            }}},[_vm._v("zurück zur Übersicht")])],1)],1)]},proxy:true}])}),_c('div',{staticClass:"content content-narrow"},[_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('base-block',{attrs:{"rounded":"","header-bg":"","content-full":""}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-table-simple',{staticClass:"table-vcenter font-size-sm mb-0",attrs:{"striped":"","hover":"","borderless":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"font-w700 cursor-pointer",class:{
                        'text-black': _vm.tableSortKey == 'order_id_str',
                      },on:{"click":function($event){return _vm.tableSortBy('order_id_str')}}},[_vm._v("Auftragsnr. "),(_vm.tableSortKey !== 'order_id_str')?_c('i',{staticClass:"fa fa-angle-down opacity-medium"}):_vm._e(),(
                          _vm.tableSortKey == 'order_id_str' &&
                            _vm.tableSortDirection == 'asc'
                        )?_c('i',{staticClass:"fa fa-angle-down"}):_vm._e(),(
                          _vm.tableSortKey == 'order_id_str' &&
                            _vm.tableSortDirection == 'desc'
                        )?_c('i',{staticClass:"fa fa-angle-up"}):_vm._e()]),_c('b-th',{staticClass:"d-none d-sm-table-cell font-w700 cursor-pointer"},[_vm._v(" Shop ")]),_c('b-th',{staticClass:"d-none d-sm-table-cell font-w700 cursor-pointer",class:{
                        'text-black':
                          _vm.tableSortKey == 'delivery_date_supplier',
                      },on:{"click":function($event){return _vm.tableSortBy('delivery_date_supplier')}}},[_vm._v("Liefertermin "),(_vm.tableSortKey !== 'delivery_date_supplier')?_c('i',{staticClass:"fa fa-angle-down opacity-medium"}):_vm._e(),(
                          _vm.tableSortKey == 'delivery_date_supplier' &&
                            _vm.tableSortDirection == 'asc'
                        )?_c('i',{staticClass:"fa fa-angle-down"}):_vm._e(),(
                          _vm.tableSortKey == 'delivery_date_supplier' &&
                            _vm.tableSortDirection == 'desc'
                        )?_c('i',{staticClass:"fa fa-angle-up"}):_vm._e()]),_c('b-th',{staticClass:"font-w700 cursor-pointer",class:{ 'text-black': _vm.tableSortKey == 'commission' },on:{"click":function($event){return _vm.tableSortBy('commission')}}},[_vm._v("Kommission "),(_vm.tableSortKey !== 'commission')?_c('i',{staticClass:"fa fa-angle-down opacity-medium"}):_vm._e(),(
                          _vm.tableSortKey == 'commission' &&
                            _vm.tableSortDirection == 'asc'
                        )?_c('i',{staticClass:"fa fa-angle-down"}):_vm._e(),(
                          _vm.tableSortKey == 'commission' &&
                            _vm.tableSortDirection == 'desc'
                        )?_c('i',{staticClass:"fa fa-angle-up"}):_vm._e()]),_c('b-th',{staticClass:"font-w700 cursor-pointer",class:{
                        'text-black': _vm.tableSortKey == 'current_status',
                      },on:{"click":function($event){return _vm.tableSortBy('current_status')}}},[_vm._v("Status "),(_vm.tableSortKey !== 'current_status')?_c('i',{staticClass:"fa fa-angle-down opacity-medium"}):_vm._e(),(
                          _vm.tableSortKey == 'current_status' &&
                            _vm.tableSortDirection == 'asc'
                        )?_c('i',{staticClass:"fa fa-angle-down"}):_vm._e(),(
                          _vm.tableSortKey == 'current_status' &&
                            _vm.tableSortDirection == 'desc'
                        )?_c('i',{staticClass:"fa fa-angle-up"}):_vm._e()])],1)],1),(_vm.filteredList.length)?_c('b-tbody',_vm._l((_vm.filteredList),function(order){return _c('b-tr',{key:order.order_id_str},[_c('b-td',[(order.order_id_str)?_c('router-link',{staticClass:"font-w600",attrs:{"to":{
                          name: 'manufactureOrder',
                          params: {
                            orderid: order.order_id_str,
                          },
                        }}},[_vm._v(" "+_vm._s(order.order_id_str)+" ")]):_vm._e()],1),_c('b-td',{staticClass:"d-none d-sm-table-cell"},[_vm._v(" "+_vm._s(order.shop_id)+" ")]),_c('b-td',{staticClass:"d-none d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.$d(new Date(order.delivery_date_supplier), "short"))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(order.commission)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.$t("orders.status." + order.current_status))+" ")])],1)}),1):_c('b-tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t("orders.searchResult.noResults"))+" ")])])]),_c('b-tfoot',[_c('b-tr',[_c('b-td',{attrs:{"colspan":"4"}},[_vm._v(" Anzahl Aufträge: "),_c('b',[_vm._v(_vm._s(_vm.filteredList.length))])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }