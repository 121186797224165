<template>
  <!-- Footer -->
  <footer id="page-footer">
    <slot>
      <div class="content py-3">
        <b-row class="font-size-sm">
          <b-col order-sm="1" class="py-1 text-center text-sm-left">
            egfB2B v1.4.3 &copy; {{ currentYear }} by egf - Eduard G. Fidel GmbH
            <!-- <span v-if="$store.state.layout.localeChanger"> - {{ $t("footer.language") }}: <locale-changer /></span>-->
            <span> - {{ $t("footer.language") }}: <locale-changer /></span>
            <span class="text-white"> - {{ version }}</span>
          </b-col>
        </b-row>
      </div>
    </slot>
  </footer>
  <!-- END Footer -->
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
  components: { LocaleChanger },
  name: "BaseFooter",
  props: {
    classes: String,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    version() {
      return process.env.NODE_ENV;
    },
  },
};
</script>
