<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('orders.search.title')">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'Home',
                params: { shopid: shopId },
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{
            $t("orders.search.title")
          }}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content content-narrow">
      Formular Auftragssuche
    </div>
    <!-- END Page Content -->
  </section>
</template>

<script>
import _ from "lodash";

import Loading from "@/store/loading";
import OrdersList from "@/assets/js/OrdersList";

export default {
  name: "OrdersList",

  data() {
    return {
      shopId: null,
      filter: "all",
      tableSortKey: "order_id_str",
      tableSortDirection: "asc",
      activeOrders: [
        {
          commission: "",
          currency: "EUR",
          current_status: "",
          delivery_date_customer: "2021-01-01",
          delivery_date_supplier: "2021-01-01",
          exchange_rate: 1,
          notes: "",
          notes_internal: "",
          order_id: 0,
          order_id_str: "",
          order_month: 0,
          order_type: "",
          order_year: 0,
          shop_id: 0,
          type: "",
          vat: 0,
          wedding_date: null,
        },
      ],
    };
  },
  methods: {
    loadData(shopId) {
      return OrdersList.getListActive(shopId);
    },
    showList(shopId) {
      Loading.dispatch("increase", 1);

      this.loadData(shopId)
        .then(data => {
          this.activeOrders = [];
          for (let orderData of data) {
            this.activeOrders.push(orderData);
          }
          Loading.dispatch("reduce", 1);
        })
        .catch(function(err) {
          // There was an error
          console.log("No active order list available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    tableSortBy(sortKey) {
      if (sortKey == this.tableSortKey) {
        if (this.tableSortDirection == "asc") {
          this.tableSortDirection = "desc";
        } else {
          this.tableSortDirection = "asc";
        }
      } else {
        this.tableSortKey = sortKey;
        this.tableSortDirection = "asc";
      }
    },
  },
  computed: {
    filteredList() {
      if (this.filter != "all" && this.filter != "") {
        return _.orderBy(
          this.activeOrders.filter(
            order => !order.current_status.indexOf(this.filter),
          ),
          [this.tableSortKey],
          [this.tableSortDirection],
        );
      }

      return _.orderBy(
        this.activeOrders,
        [this.tableSortKey],
        [this.tableSortDirection],
      );
    },
  },
  mounted() {
    document.title = this.$t("dashboard.title");
    this.shopId = this.$route.params.shopid;

    if (typeof this.$route.query.filter != "undefined") {
      this.filter = this.$route.query.filter;
      this.$router.replace({ query: null });
    }

    this.showList(this.shopId);
  },
};
</script>
