<template>
  <div>
    <!-- Page Content -->
    <div class="content">
      <!-- Hero -->
      <base-block rounded content-full>
        <div class="py-1 text-center">
          <h1 class="mb-0 my-0 py-0">
            {{ $t("customer.orderLogin.title") }}
          </h1>
        </div>
      </base-block>
      <!-- END Hero -->
      <!-- Order Login Content -->
      <base-block header-class="bg-primary text-center" themed>
        <p class="text-center">
          {{ $t("customer.orderLogin.textCreation") }}
        </p>
        <p class="text-center">
          <LoadingSpinner />
        </p>
        <p class="text-center">
          {{ $t("customer.orderLogin.textWait") }}
        </p>
      </base-block>
    </div>
  </div>
</template>

<script>
import AuthStore from "@/store/auth";
import ShoppingcartStore from "@/store/shoppingcart";

import ShoppingCart from "@/assets/js/ShoppingCart";

import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CustomerOrderLogin",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      shopId: this.$route.params.shopid,
    };
  },
  async mounted() {
    if (this.shouldCreateOrder()) {
      try {
        const result = await this.createOrder();
        if (result.status === "true") {
          const confirmationUrl = `/${this.shopId}/customer/orderconfirmation/${result.order_id}`;
          this.$router.push(confirmationUrl);
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        this.$router.go(-1);
      }
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    shouldCreateOrder() {
      return AuthStore.getters.jwt && ShoppingcartStore.getters.orderData;
    },
    createOrder() {
      return ShoppingCart.createOrder(
        this.shopId,
        ShoppingcartStore.getters.orderData,
      );
    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";
</style>
