<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('orderCreateService.title') + ' ' + orderId">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'Home',
                params: { shopid: shopId },
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'OrderDetail',
                params: { shopid: shopId, orderid: orderId },
              }"
              >{{ $t("layout.breadcrumb.order") }} {{ orderId }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{
            $t("orders.active.title")
          }}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>

    <div class="content" v-if="isOrderIdValid">
      <b-row>
        <b-col>
          <base-block
            :title="$t('orderCreateService.type.headline')"
            header-class="bg-primary"
            themed
          >
            <b-form-group>
              <b-form-radio-group
                v-model="serviceType"
                :options="serviceTypeList"
                @change="changeServiceType()"
              ></b-form-radio-group>
            </b-form-group>
          </base-block>

          <!-- Bearbeitung des Service -->
          <base-block
            v-if="serviceType == '1'"
            :title="$t('orderCreateService.service.processing')"
            header-class="bg-primary"
            themed
          >
            <b-form-group>
              <b-form-radio-group
                name="service-processing"
                v-model="serviceProcessing"
                :options="serviceProcessingList"
                switches
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </base-block>

          <!-- Service - Liste für nachträgliche Gravuren -->
          <base-block
            v-if="serviceType == '1' && serviceProcessing.includes('9')"
            :title="$t('orderCreateService.service.processingEngraving')"
            header-class="bg-primary"
            themed
          >
            <b-form-group>
              <b-form-radio-group
                name="service-processing-engraving"
                v-model="serviceProcessingEngraving"
                :options="serviceProcessingEngravingList"
                switches
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </base-block>

          <!-- Grund der Reklamation -->
          <base-block
            v-if="serviceType == '2'"
            :title="$t('orderCreateService.complaint.reason')"
            header-class="bg-primary"
            themed
          >
            <b-form-group>
              <b-form-select
                id="complaint-reason"
                v-model="complaintReason"
                :options="complaintReasonList"
                plain
              ></b-form-select>
            </b-form-group>
          </base-block>

          <!-- Bearbeitung der Reklamation -->
          <base-block
            v-if="serviceType === '2' && complaintReason !== null"
            :title="$t('orderCreateService.complaint.processing')"
            header-class="bg-primary"
            themed
          >
            <b-form-group>
              <b-form-select
                id="complaint-reason"
                v-model="complaintProcessing"
                :options="complaintProcessingList"
                plain
              ></b-form-select>
            </b-form-group>
          </base-block>
        </b-col>
      </b-row>
      <b-row v-if="showItems">
        <b-col sm="12" v-for="(item, itemKey) in order.items" :key="itemKey">
          <base-block :title="item.name" header-class="bg-primary" themed>
            <b-row>
              <b-col sm="8">
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("orderCreateService.articlenumber") }}:
                        {{ item.item_number }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600">
                        {{ $t("orderCreateService.size") }}:
                        {{ item.details.size }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <hr class="mt-1 mb-2" />
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <b-form-checkbox
                          :id="'check-addToService-' + item.item_id"
                          v-model="item.addToService"
                          :name="'checkbox-' + item.item_id"
                          value="1"
                          unchecked-value="0"
                          class="py-2"
                        >
                          {{
                            $t("orderCreateService.addArticleToServiceOrder")
                          }}
                        </b-form-checkbox>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="item.addToService == '1'">
                      <b-td>
                        <b-form-textarea
                          rows="5"
                          :placeholder="
                            $t('orderCreateService.manufacturingNotes')
                          "
                          v-model="item.manufacturingNotes"
                          class="py-2 mt-2 mb-4"
                        ></b-form-textarea>
                        <b-table-simple
                          v-if="serviceType === '1'"
                          responsive
                          borderless
                          small
                          table-class="table-vcenter"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td class="w-50 text-right" style="">
                                {{ $t("orderCreateService.price") }} €
                              </b-td>
                              <b-td>
                                <b-form-input
                                  type="number"
                                  v-model="item.servicePrice"
                                  maxlength="10"
                                ></b-form-input>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                &nbsp;
                              </b-td>
                              <b-td class="text-right">
                                <b-button
                                  @click="
                                    showPriceList[itemKey] = !showPriceList[
                                      itemKey
                                    ]
                                  "
                                  size="lg"
                                  variant="alt-info"
                                  class="btn-rounded px-6 mr-2 mb-2"
                                >
                                  <i class="si si-list mr-1"></i>
                                  <span v-if="!showPriceList[itemKey]">{{
                                    $t("orderCreateService.pricelistShow")
                                  }}</span>
                                  <span v-else-if="showPriceList[itemKey]">{{
                                    $t("orderCreateService.pricelistHide")
                                  }}</span>
                                </b-button>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col sm="4">
                <b-img
                  fluid
                  rounded
                  class="py-1 px-4"
                  style="border: 1px solid #ededed"
                  :src="item.details.image[0]"
                />
              </b-col>
            </b-row>

            <b-row v-if="showPriceList[itemKey]">
              <b-col>
                <object
                  v-if="currentLanguage != 'de'"
                  data="/pdf/2018-01-01-TRJ-Servicepricelist_EN_VK.pdf"
                  style="width: 100%; height: 100vh; overflow: hidden;"
                >
                  <a href="/pdf/2018-01-01-TRJ-Servicepricelist_EN_VK.pdf"
                    >load PDF</a
                  >
                </object>
                <object
                  v-else
                  data="/pdf/2017-02-01-TRJ-Servicepreisliste_EK_VK.pdf"
                  style="width: 100%; height: 100vh; overflow: hidden;"
                >
                  <a href="/pdf/2017-02-01-TRJ-Servicepreisliste_EK_VK.pdf"
                    >PDF laden</a
                  >
                </object>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
      <base-block
        v-if="showCreateForm"
        title="Bestellung"
        header-class="bg-primary"
        themed
      >
        <b-row>
          <b-col md="6">
            <b-form-group label-for="customerPickupDate">
              <template #label>
                {{ $t("orderCreateService.formData.customerPickupDate") }}
                <span class="text-danger">*</span>
              </template>
              <flat-pickr
                id="customerPickupDate"
                class="form-control bg-white"
                placeholder="Abholtermin Kunde auswählen"
                v-model="customerPickupDate"
                :config="flatPickrConfig"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="salesperson">
              <template #label>
                {{ $t("orderCreateService.formData.salesperson") }}
              </template>
              <b-form-input
                v-model="order.salesperson"
                id="salesperson"
                class="form-control bg-white"
                type="text"
                name="salesperson"
                maxlength="25"
                :placeholder="
                  $t('orderCreateService.formData.salespersonPlaceholder')
                "
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              @click="createServiceOrder()"
              size="lg"
              variant="alt-success"
              class="btn-rounded px-6 mr-2 mb-4"
            >
              <i class="si si-rocket mr-1"></i>
              {{ $t("orderCreateService.buttonCreate") }}
            </b-button>
          </b-col>
        </b-row>
      </base-block>
    </div>
  </section>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import OrderStore from "@/store/order";

import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";
import i18n from "@/i18n";

export default {
  name: "OrderCreateService",
  components: {
    flatPickr,
  },
  data() {
    return {
      // Get more form https://flatpickr.js.org/options/
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d", // Date Format displayed in the input field
        altInput: true,
        dateFormat: "Y-m-d", // Date Format used for submitting
        locale: English,
      },
      currentLanguage: i18n.locale,
      isOrderIdValid: false,
      orderId: "",
      shopId: "",
      serviceType: "",
      serviceTypeList: [
        { value: "1", text: i18n.t("orderCreateService.type.service") },
        {
          value: "2",
          text: i18n.t("orderCreateService.type.complaint"),
        },
      ],
      showPriceList: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
      },
      serviceProcessing: "",
      serviceProcessingList: [
        {
          value: "1",
          text: i18n.t("orderCreateService.serviceProcessing.changeRingsize"),
        },
        {
          value: "2",
          text: i18n.t(
            "orderCreateService.serviceProcessing.ringRefurbishment",
          ),
        },
        {
          value: "3",
          text: i18n.t("orderCreateService.serviceProcessing.newProduction"),
        },
        {
          value: "4",
          text: i18n.t("orderCreateService.serviceProcessing.stoneReplacement"),
        },
        {
          value: "5",
          text: i18n.t("orderCreateService.serviceProcessing.stoneAddition"),
        },
        {
          value: "9",
          text: i18n.t(
            "orderCreateService.serviceProcessing.subsequentEngraving",
          ),
        },
        {
          value: "6",
          text: i18n.t("orderCreateService.serviceProcessing.other"),
        },
      ],
      serviceProcessingEngraving: "",
      serviceProcessingEngravingList: [
        {
          value: "9-1",
          text: i18n.t(
            "orderCreateService.serviceProcessingEngraving.machineEngraving",
          ),
        },
        {
          value: "9-2",
          text: i18n.t(
            "orderCreateService.serviceProcessingEngraving.laserInside",
          ),
        },
        {
          value: "9-3",
          text: i18n.t(
            "orderCreateService.serviceProcessingEngraving.laserOutside",
          ),
        },
        {
          value: "9-4",
          text: i18n.t(
            "orderCreateService.serviceProcessingEngraving.laserOnSide",
          ),
        },
        {
          value: "9-5",
          text: i18n.t(
            "orderCreateService.serviceProcessingEngraving.faksimile",
          ),
        },
      ],
      complaintReason: null,
      complaintReasonList: [
        {
          value: null,
          text: i18n.t("orderCreateService.complaintReason.choose"),
        },
        {
          label: i18n.t("orderCreateService.complaintReason.profile"),
          options: [
            {
              value: "1-1",
              text: i18n.t(
                "orderCreateService.complaintReason.profileDeviatingKonfiguration",
              ),
            },
            {
              value: "1-2",
              text: i18n.t(
                "orderCreateService.complaintReason.profileDeviatingHeight",
              ),
            },
            {
              value: "1-3",
              text: i18n.t(
                "orderCreateService.complaintReason.profileDeviatingWidth",
              ),
            },
            {
              value: "1-4",
              text: i18n.t(
                "orderCreateService.complaintReason.profileDeviatingForm",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.grooves"),
          options: [
            {
              value: "2-1",
              text: i18n.t(
                "orderCreateService.complaintReason.groovesDeviatingKonfiguration",
              ),
            },
            {
              value: "2-2",
              text: i18n.t(
                "orderCreateService.complaintReason.groovesDeviatingPlacement",
              ),
            },
            {
              value: "2-3",
              text: i18n.t("orderCreateService.complaintReason.groovesMissing"),
            },
            {
              value: "2-4",
              text: i18n.t(
                "orderCreateService.complaintReason.groovesInadequateExecution",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.surface"),
          options: [
            {
              value: "3-1",
              text: i18n.t(
                "orderCreateService.complaintReason.surfaceDeviatingKonfiguration",
              ),
            },
            {
              value: "3-2",
              text: i18n.t(
                "orderCreateService.complaintReason.surfaceDeviatingExecution",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.stones"),
          options: [
            {
              value: "4-1",
              text: i18n.t(
                "orderCreateService.complaintReason.stonesDeviatingKonfiguration",
              ),
            },
            {
              value: "4-2",
              text: i18n.t(
                "orderCreateService.complaintReason.stonesWrongStonesize",
              ),
            },
            {
              value: "4-3",
              text: i18n.t(
                "orderCreateService.complaintReason.stonesWrongStonequality",
              ),
            },
            {
              value: "4-4",
              text: i18n.t(
                "orderCreateService.complaintReason.stonesFallenoutLooseBroken",
              ),
            },
            {
              value: "4-5",
              text: i18n.t(
                "orderCreateService.complaintReason.stonesSettingUnclean",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.ringsize"),
          options: [
            {
              value: "5-1",
              text: i18n.t(
                "orderCreateService.complaintReason.ringsizeTooLarge",
              ),
            },
            {
              value: "5-2",
              text: i18n.t(
                "orderCreateService.complaintReason.ringsizeTooSmall",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.engraving"),
          options: [
            {
              value: "6-1",
              text: i18n.t(
                "orderCreateService.complaintReason.engravingWrongText",
              ),
            },
            {
              value: "6-2",
              text: i18n.t(
                "orderCreateService.complaintReason.engravingTooWeak",
              ),
            },
            {
              value: "6-3",
              text: i18n.t(
                "orderCreateService.complaintReason.engravingUnfavourablePlacement",
              ),
            },
            {
              value: "6-4",
              text: i18n.t(
                "orderCreateService.complaintReason.engravingWrongAlloyHallmarked",
              ),
            },
            {
              value: "6-5",
              text: i18n.t(
                "orderCreateService.complaintReason.engravingWrongStoneInfoEngraved",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.finish"),
          options: [
            {
              value: "7-1",
              text: i18n.t(
                "orderCreateService.complaintReason.finishNotRhodiumPlated",
              ),
            },
            {
              value: "7-2",
              text: i18n.t(
                "orderCreateService.complaintReason.finishRhodiumIsStained",
              ),
            },
            {
              value: "7-3",
              text: i18n.t(
                "orderCreateService.complaintReason.finishSharpEdges",
              ),
            },
            {
              value: "7-4",
              text: i18n.t(
                "orderCreateService.complaintReason.finishScratches",
              ),
            },
          ],
        },
        {
          label: i18n.t("orderCreateService.complaintReason.material"),
          options: [
            {
              value: "8-1",
              text: i18n.t(
                "orderCreateService.complaintReason.materialRingBroken",
              ),
            },
            {
              value: "8-2",
              text: i18n.t(
                "orderCreateService.complaintReason.materialPoresInMaterial",
              ),
            },
          ],
        },
        {
          value: "9-1",
          text: i18n.t("orderCreateService.complaintReason.other"),
        },
      ],
      complaintProcessing: null,
      complaintProcessingList: [
        {
          value: null,
          text: i18n.t("orderCreateService.complaintProcessing.choose"),
        },
        {
          value: "3",
          text: i18n.t("orderCreateService.complaintProcessing.newProduction"),
        },
        {
          value: "7",
          text: i18n.t(
            "orderCreateService.complaintProcessing.sendInForCorrection",
          ),
        },
        {
          value: "8",
          text: i18n.t(
            "orderCreateService.complaintProcessing.sendInForMaterialInspection",
          ),
        },
      ],
      order: {
        order_year: 0,
        order_month: 0,
        order_id: 0,
        order_type: "A",
        shop_id: 0,
        type: "",
        delivery_date_customer: "2021-01-01",
        salesperson: "",
        items: [
          {
            item_id: 0,
            image: [],
            addToService: "0",
            manufacturingNotes: "",
            servicePrice: 0,
          },
        ],
      },
    };
  },
  methods: {
    loadData(shopId, orderId) {
      return Order.get(shopId, orderId);
    },
    show(shopId, orderId) {
      Loading.dispatch("increase", 1);

      this.loadData(shopId, orderId)
        .then(data => {
          Loading.dispatch("reduce", 1);
          this.order = data.order;
          this.isOrderIdValid = true;
        })
        .catch(function(err) {
          console.log("No order data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    setCustomerPickupDate() {
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 14);
      let proposalCustomerPickupDate = dateObj.toISOString().slice(0, 10);
      this.customerPickupDate = proposalCustomerPickupDate;
    },
    validateInput() {
      return true;
    },
    createServiceOrder() {
      // Show Loader
      Loading.dispatch("increase", 1);

      // validate important form data
      if (this.validateInput() === false) {
        return false;
      }

      // collect form data for order
      const serviceOrderData = {
        shopId: this.shopId,
        orderType: this.order.order_type,
        orderYear: this.order.order_year,
        orderMonth: this.order.order_month,
        orderId: this.order.order_id,

        commission: this.order.commission,
        customerPickupDate: this.customerPickupDate,
        salesperson: this.order.salesperson,
        exchangeRate: this.order.exchange_rate,
        vat: this.order.vat,
        currency: this.order.currency,

        origin: this.order.origin,
        origin_id: this.order.origin_id,

        serviceType: this.serviceType,

        serviceProcessing: this.serviceProcessing,
        serviceProcessingEngraving: this.serviceProcessingEngraving,

        complaintReason: this.complaintReason,
        complaintProcessing: this.complaintProcessing,

        items: [],
      };

      const items = [];

      for (const [key, value] of Object.entries(this.order.items)) {
        if (
          typeof value.addToService == "undefined" ||
          value.addToService == "0"
        ) {
          continue;
        }

        const tmp = {
          itemnumber: value.item_number,
          size: value.size,
          name: value.name,
          addToService: value.addToService,
          manufacturingNotes: value.manufacturingNotes,
          servicePrice: value.servicePrice,
        };

        items[key] = tmp;
      }

      serviceOrderData["items"] = items;
      // save form data in Store
      OrderStore.dispatch("setServiceOrderData", serviceOrderData);

      // send order directly to backend
      Order.createServiceOrder(this.shopId, serviceOrderData).then(result => {
        if (result.ok === true) {
          let redirectUrl =
            "/" + result.shop_id + "/orders/detail/" + result.order_id;
          this.$router.push(redirectUrl);
        } else {
          alert("Error - Bitte wenden Sie sich an den Administrator");
          Loading.dispatch("reduce", 1);
        }
      });
    },
    changeServiceType() {
      // reset form data
      this.serviceProcessing = "";
      this.serviceProcessingEngraving = "";

      this.complaintReason = null;
      this.complaintProcessing = null;

      for (const [key] of Object.entries(this.order.items)) {
        if (key !== null) {
          if (this.order.items[key].addToService == "1") {
            this.order.items[key].addToService = "0";
          }

          if (this.order.items[key].manufacturingNotes != "") {
            this.order.items[key].manufacturingNotes = "";
          }

          if (this.order.items[key].servicePrice != 0) {
            this.order.items[key].servicePrice = 0;
          }
        }
      }
    },
    reinitializeFlatpickr() {
      if (this.$refs.flatPickr) {
        const flatpickrInstance = this.$refs.flatPickr.flatpickr;
        if (flatpickrInstance) {
          flatpickrInstance.destroy();
          this.$refs.flatPickr.flatpickr(this.flatPickrConfig);
        }
      }
    },
  },
  watch: {
    "$i18n.locale": {
      handler(newLocale) {
        const locales = {
          de: German,
          nl: Dutch,
          fr: French,
          ja: Japanese,
          ko: Korean,
          en: English,
        };
        this.flatPickrConfig.locale = locales[newLocale] || English;

        switch (newLocale) {
          case "de":
            this.flatPickrConfig.altFormat = "d.m.Y";
            break;
          case "nl":
            this.flatPickrConfig.altFormat = "d-m-Y";
            break;
          case "fr":
            this.flatPickrConfig.altFormat = "d/m/Y";
            break;
          case "ja":
            this.flatPickrConfig.altFormat = "Y/m/d";
            break;
          case "ko":
            this.flatPickrConfig.altFormat = "Y.m.d";
            break;
          case "en":
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
          default:
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
        }

        this.$nextTick(() => {
          this.reinitializeFlatpickr();
        });
      },
      immediate: true,
    },
  },
  computed: {
    showItems() {
      let show = false;

      if (this.serviceType == "2" && this.complaintProcessing !== null) {
        show = true;
      } else if (this.serviceType == "1" && this.serviceProcessing.length > 0) {
        if (
          this.serviceProcessing.includes("9") &&
          this.serviceProcessingEngraving.length > 0
        ) {
          show = true;
        } else if (!this.serviceProcessing.includes("9")) {
          show = true;
        }
      }

      return show;
    },
    showCreateForm() {
      if (this.showItems === false) {
        return false;
      }

      let show = false;

      for (const [key, value] of Object.entries(this.order.items)) {
        if (key !== null) {
          if (
            typeof value.addToService !== "undefined" &&
            value.addToService == "1"
          ) {
            show = true;
          }
        }
      }

      return show;
    },
  },
  mounted() {
    this.shopId = this.$route.params.shopid;
    this.orderId = this.$route.params.orderid;

    Notification.init(this);

    this.show(this.shopId, this.orderId);

    this.setCustomerPickupDate();
  },
};
</script>
