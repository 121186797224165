<template>
  <section>
    <!-- Hero -->
    <base-page-heading title="egfB2B Innendienst - Aktive Aufträge">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              :to="{
                name: 'manufactureHome',
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            egf Innendienst Übersicht
          </b-breadcrumb-item>
        </b-breadcrumb>
        <div class="text-right">
          <b-button-group>
            <b-button
              variant="outline-primary"
              :to="{
                name: 'manufactureArchive',
              }"
              >Archiv Aufträge</b-button
            >
          </b-button-group>
        </div>
      </template>
    </base-page-heading>
    <!-- END Hero -->
    <div class="content content-narrow">
      <b-row>
        <b-col xl="6">
          <base-block rounded header-bg content-full>
            <b-form-select
              v-model="shop_filter"
              :options="shops"
              class="w-100"
            />
          </base-block>
        </b-col>
        <b-col xl="6">
          <base-block rounded header-bg content-full>
            <b-form-select
              v-model="year_filter"
              :options="years"
              @change="showList"
              class="w-100"
            />
          </base-block>
        </b-col>
        <b-col xl="12">
          <!-- Active Orders -->
          <base-block rounded title="Aktive Aufträge" header-bg content-full>
            <b-table-simple
              striped
              hover
              borderless
              class="table-vcenter font-size-sm mb-0"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    class="font-w700 cursor-pointer"
                    @click="sortBy('order_id_str')"
                    >{{ $t("dashboard.boxes.latestOrders.orderNumber") }}</b-th
                  >
                  <b-th
                    class="d-none d-sm-table-cell font-w700 cursor-pointer"
                    @click="sortBy('shop_id')"
                    >Shop</b-th
                  >
                  <b-th
                    class="d-none d-sm-table-cell font-w700 cursor-pointer"
                    @click="sortBy('delivery_date_supplier')"
                    >{{ $t("dashboard.boxes.latestOrders.deliveryDate") }}</b-th
                  >
                  <b-th
                    class="font-w700 cursor-pointer"
                    @click="sortBy('commission')"
                    >{{ $t("dashboard.boxes.latestOrders.commission") }}</b-th
                  >
                  <b-th
                    class="font-w700 cursor-pointer"
                    @click="sortBy('current_status')"
                    >{{ $t("dashboard.boxes.latestOrders.status") }}</b-th
                  >
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="order in filteredList" :key="order.order_id_str">
                  <b-td>
                    <router-link
                      class="font-w600"
                      v-if="order.order_id_str"
                      :to="{
                        name: 'manufactureOrder',
                        params: {
                          orderid: order.order_id_str,
                        },
                      }"
                    >
                      {{ order.order_id_str }}
                    </router-link>
                  </b-td>
                  <b-td>
                    {{ order.shop_id }}
                  </b-td>
                  <b-td
                    class="d-none d-sm-table-cell text-black"
                    :class="{
                      'bg-danger': order.delivery_date_supplier_days <= 0,
                      'bg-warning': order.delivery_date_supplier_days <= 5,
                      'bg-success': order.delivery_date_supplier_days > 5,
                    }"
                  >
                    {{ $d(new Date(order.delivery_date_supplier), "short") }}
                  </b-td>
                  <b-td>
                    {{ order.commission }}
                  </b-td>
                  <b-td>
                    {{ $t("orders.status." + order.current_status) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
        <!-- END Active Orders -->
        <b-col xl="12">
          <base-block rounded header-bg content-full
            >Anzahl der Aufträge in der Liste:
            {{ filteredList.length }}</base-block
          ></b-col
        >
      </b-row>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { upperCase } from "lodash";

import Loading from "@/store/loading";

import ManufactureOrdersList from "@/assets/js/ManufactureOrdersList";
import ManufactureShops from "@/assets/js/ManufactureShops";

export default {
  name: "ManufactureHome",

  data() {
    return {
      shop_filter: 0,
      shops: [{ value: 0, text: "Alle Shops" }],
      year_filter: 0,
      tableSortKey: "delivery_date_supplier",
      tableSortDirection: "asc",
      activeOrders: [],
    };
  },
  methods: {
    async showList() {
      Loading.dispatch("increase", 1);

      this.activeOrders = [];

      const data = await ManufactureOrdersList.getListActive(this.year_filter);

      if (data.length > 0) {
        for (let orderData of data) {
          this.activeOrders.push(orderData);
        }
      }
      Loading.dispatch("reduce", 1);
    },
    async getShopList() {
      this.shops = [{ value: 0, text: "Alle Shops" }];

      const data = await ManufactureShops.getList();

      if (data.length > 0) {
        for (let shopData of data) {
          this.shops.push({
            value: shopData.shop_id,
            text:
              upperCase(shopData.country) +
              " - " +
              shopData.city +
              " - " +
              shopData.name +
              " (" +
              shopData.shop_id +
              ")",
          });
        }
      }
    },
    sortBy(key) {
      if (this.tableSortKey === key) {
        this.tableSortDirection =
          this.tableSortDirection === "asc" ? "desc" : "asc";
      } else {
        this.tableSortKey = key;
        this.tableSortDirection = "asc";
      }
    },
  },
  computed: {
    filteredList() {
      if (this.shop_filter > 0) {
        return _.slice(
          _.orderBy(
            this.activeOrders.filter(
              order => order.shop_id === this.shop_filter,
            ),
            [this.tableSortKey],
            [this.tableSortDirection],
          ),
          0,
          10000,
        );
      } else {
        return _.slice(
          _.orderBy(
            this.activeOrders,
            [this.tableSortKey],
            [this.tableSortDirection],
          ),
          0,
          10000,
        );
      }
    },
    years() {
      const firstYear = 2022;
      const currentYear = new Date().getFullYear();
      const years = [{ value: 0, text: "Alle Jahre" }];
      for (let year = currentYear; year >= firstYear; year--) {
        years.push({ value: year - 2000, text: year });
      }
      return years;
    },
  },
  mounted() {
    document.title = "egf Innendienst Übersicht";

    const self = this;
    self.showList();

    setTimeout(() => {
      self.getShopList();
    }, 1000);
  },
};
</script>
