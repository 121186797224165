import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueCookies from "vue-cookies";

// Bootstrap Vue
import BootstrapVue from "bootstrap-vue";

// Custom components
import BaseLayoutModifier from "@/components/BaseLayoutModifier";
import BaseBlock from "@/components/BaseBlock";
import BaseBackground from "@/components/BaseBackground";
import BasePageHeading from "@/components/BasePageHeading";
import BaseNavigation from "@/components/BaseNavigation";

// Register global plugins
Vue.use(BootstrapVue);
Vue.use(VueCookies);

// Register global components
Vue.component(BaseLayoutModifier.name, BaseLayoutModifier);
Vue.component(BaseBlock.name, BaseBlock);
Vue.component(BaseBackground.name, BaseBackground);
Vue.component(BasePageHeading.name, BasePageHeading);
Vue.component(BaseNavigation.name, BaseNavigation);

Vue.config.productionTip = false;

Vue.$cookies.config("30d");

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");

/* get shop language and set it as default */
i18n.locale = Vue.$cookies.get("egfb2b-language") || "de";

/* set the htmlAttrs lang attribute to the current i18n language */
document.documentElement.setAttribute("lang", i18n.locale);
