<template>
  <base-block :title="$t('order.costs.title')" header-class="bg-primary" themed>
    <b-table-simple responsive small borderless table-class="table-vcenter">
      <b-thead>
        <b-tr>
          <b-td class="font-w600">
            {{ $t("order.costs.article") }}
          </b-td>
          <b-td class="font-w600 text-right" style="width: 15%;">
            {{ $t("order.costs.single") }}
          </b-td>
          <b-td class="font-w600 text-right" style="width: 15%;">
            {{ $t("order.costs.amount") }}
          </b-td>
          <b-td class="font-w600 text-right" style="width: 15%;">
            {{ $t("order.costs.total") }}
          </b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, idx) in order.items" :key="idx">
          <b-td>
            <span v-if="orderData.order_type == 'P'"
              >{{ $t("order.costs.sampleReference") }}&nbsp;</span
            >{{ item.item_number }} ({{ $t("order.costs.sizeShort") }}
            {{ item.size }})
          </b-td>
          <b-td class="text-right">
            {{ $n(item.price_total) }} {{ orderData.currency }}
          </b-td>
          <b-td class="text-right">
            1
          </b-td>
          <b-td class="text-right">
            {{ $n(item.price_total) }} {{ orderData.currency }}
          </b-td>
        </b-tr>
        <!-- Net Amount
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="2" class="text-right border-top">
            {{ $t("order.costs.subtotal") }}
          </b-td>
          <b-td class="text-right border-top">
            {{ $n(orderData.total_net_amount) }} {{ orderData.currency }}
          </b-td>
        </b-tr>
        -->
        <!-- sum without surcharge / discount | Zwischensumme ohne Rabatt / Aufschlag -->
        <b-tr v-if="orderData.surcharge_discount != 0">
          <b-td>&nbsp;</b-td>
          <b-td>&nbsp;</b-td>
          <b-td>&nbsp;</b-td>
          <b-td class="text-right border-top">
            {{ $n(orderData.total_amount_original) }} {{ orderData.currency }}
          </b-td>
        </b-tr>

        <!-- surcharge / discount -->
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="2" class="text-right">
            <!-- Edit Icon -->
            <i
              v-if="edit.surcharge_discount !== true"
              class="far fa-edit ml-2 p-2 cursor-pointer"
              @click="
                editInput('surcharge_discount');
                editInput('surcharge_discount_description');
              "
            />
            <span v-if="edit.surcharge_discount !== true">
              <span
                v-if="
                  orderData.surcharge_discount_description == '' ||
                    orderData.surcharge_discount_description == null
                "
              >
                {{ $t("order.costs.surcharge_discount") }}&nbsp;
              </span>
              {{ orderData.surcharge_discount_description }}
            </span>
            <b-input-group v-else>
              <b-form-input
                type="text"
                v-model="orderData.surcharge_discount_description"
                maxlength="25"
                :placeholder="$t('order.costs.surcharge_discount_placeholder')"
                @keyup.enter="
                  saveInput(
                    'surcharge_discount',
                    'surcharge_discount_description',
                  )
                "
              ></b-form-input>
              <!-- Revert Icon -->
              <b-button
                v-if="edit.surcharge_discount === true"
                class="px-1"
                size="md"
                variant="alt-white"
                @click="
                  revertInput('surcharge_discount');
                  revertInput('surcharge_discount_description');
                "
              >
                <i class="far fa-times-circle p-1" />
              </b-button>
              <!-- Save Icon -->
              <b-button
                v-if="edit.surcharge_discount === true"
                class="px-1"
                size="md"
                variant="alt-white"
                @click="
                  saveInput(
                    'surcharge_discount',
                    'surcharge_discount_description',
                  )
                "
              >
                <i class="far fa-check-circle p-1" />
              </b-button>
            </b-input-group>
          </b-td>
          <b-td
            class="text-right
            border-top"
          >
            <span v-if="edit.surcharge_discount !== true">
              {{ $n(orderData.surcharge_discount) }} {{ orderData.currency }}
            </span>
            <b-input-group v-else>
              <b-form-input
                type="text"
                v-model="orderData.surcharge_discount"
                maxlength="10"
                @keyup.enter="
                  saveInput(
                    'surcharge_discount',
                    'surcharge_discount_description',
                  )
                "
              ></b-form-input>
            </b-input-group>
          </b-td>
        </b-tr>
        <!-- total amount / Gesamtbetrag -->
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="2" class="font-w600 text-right">
            {{ $t("order.costs.totalAmount") }}
          </b-td>
          <b-td class="text-right">
            {{ $n(orderData.total_amount) }} {{ orderData.currency }}
          </b-td>
        </b-tr>
        <!-- vat -->
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="3" class="text-right small">
            ( {{ $t("order.costs.inclusive_short") }}
            {{ $t("order.costs.withVat", { vat: orderData.vat }) }}
            {{ $n(orderData.total_vat_amount) }} {{ orderData.currency }})
          </b-td>
        </b-tr>

        <!-- deposit / Anzahlung -->
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="2" class="text-right border-top">
            <i
              v-if="edit.deposit !== true"
              class="far fa-edit ml-2 p-2 cursor-pointer"
              @click="editInput('deposit')"
            />
            {{ $t("order.costs.deposit") }}
            <b-button
              v-if="edit.deposit === true"
              class="px-1"
              size="md"
              variant="alt-white"
              @click="revertInput('deposit')"
            >
              <i class="far fa-times-circle p-1" />
            </b-button>

            <b-button
              v-if="edit.deposit === true"
              class="px-1"
              size="md"
              variant="alt-white"
              @click="saveInput('deposit')"
            >
              <i class="far fa-check-circle p-1" />
            </b-button>
          </b-td>
          <b-td class="text-right border-top">
            <span v-if="edit.deposit !== true">
              {{ $n(orderData.deposit) }} {{ orderData.currency }}
            </span>
            <b-input-group v-else>
              <b-form-input
                type="number"
                v-model="orderData.deposit"
                maxlength="10"
                @keyup.enter="saveInput('deposit')"
              ></b-form-input>
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>&nbsp;</b-td>
          <b-td colspan="2" class="text-right">
            {{ $t("order.costs.openBalance") }}
          </b-td>
          <b-td class="text-right">
            {{ $n(orderData.balance_amount) }} {{ orderData.currency }}
          </b-td>
        </b-tr>
        <b-tr v-if="orderData.balance_amount && orderData.balance_amount > 0">
          <b-td>&nbsp;</b-td>
          <b-td colspan="4" class="text-right">
            <b-button
              size="md"
              variant="outline-secondary"
              class="m-1"
              @click="setOrderCompletelyPaid()"
              :disabled="
                orderData.status.finished == 1 ||
                  orderData.status.cancelled == 1
              "
            >
              <i class="far fa-money-bill-alt mr-1"></i>
              {{ $t("order.costs.orderCompletePaid") }}
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </base-block>
</template>

<script>
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";

export default {
  name: "OrderCosts",
  props: {
    order: {
      type: Object,
      description: "",
    },
  },
  data() {
    return {
      edit: {
        deposit: false,
        surcharge_discount: false,
        surcharge_discount_description: false,
      },
      revertValues: {
        deposit: 0,
        surcharge_discount: 0,
        surcharge_discount_description: "",
      },
      shopId: this.$route.params.shopid,
      orderId: this.$route.params.orderid,
      orderData: { ...this.order }, // Create a copy of the order prop
    };
  },
  methods: {
    editInput(key) {
      this.revertValues[key] = this.orderData[key];
      this.edit[key] = !this.edit[key];
    },
    checkInput(key, key2 = null) {
      if (key2 === "surcharge_discount_description") {
        // remove special characters from text
        if (this.orderData[key2] === "" || this.orderData[key2] === null) {
          this.orderData[key2] = "";
        } else {
          this.orderData[key2] = this.orderData[key2].replace(
            /[^a-zA-Z0-9 ]/g,
            "",
          );
        }
      } else {
        // remove all characters except numbers and dots and minus sign
        if (this.orderData[key] === "" || this.orderData[key] === null) {
          this.orderData[key] = 0;
        } else {
          if (typeof this.orderData[key] === "string") {
            this.orderData[key] = this.orderData[key].replace(/[^0-9.-]/g, "");
            this.orderData[key] = parseFloat(this.orderData[key]);
          }
        }
      }

      // check if surcharge_discount is not greater than total amount
      if (key === "surcharge_discount") {
        if (
          this.orderData[key] > this.orderData.total_amount_original ||
          this.orderData[key] < this.orderData.total_amount_original * -1
        ) {
          this.$swal(
            this.$t("notification.error"),
            this.$t("order.notification.surchargeDiscountTooHigh"),
            "error",
          );
          return false;
        }
      }

      return true;
    },
    async saveInput(key, key2 = null) {
      if (this.checkInput(key, key2)) {
        try {
          let result = false;
          if (key2 !== null) {
            result = await Order.update(
              this.shopId,
              this.orderId,
              key,
              this.orderData[key],
              key2,
              this.orderData[key2],
            );
          } else {
            result = await Order.update(
              this.shopId,
              this.orderId,
              key,
              this.orderData[key],
            );
          }

          if (result === false) {
            this.showNotification("error", "changesNotSaved");
            if (key === "surcharge_discount") {
              this.revertInput(key);
            }
          } else {
            this.showNotification("success", "changesSaved");
            if (key === "surcharge_discount" || key === "deposit") {
              await this.updateTotalAmount();
            }
          }
        } catch (error) {
          console.error("Error while saving input:", error);
        } finally {
          this.edit[key] = false;
        }
      }
    },
    revertInput(key) {
      this.orderData[key] = this.revertValues[key];
      this.revertValues[key] = "";
      this.edit[key] = false;
    },
    async updateTotalAmount() {
      try {
        const order = await Order.get(this.shopId, this.orderId);
        const data = order.order;

        this.orderData.total_amount = data.total_amount;
        this.orderData.total_vat_amount = data.total_vat_amount;
        this.orderData.deposit = data.deposit;
        this.orderData.balance_amount = data.balance_amount;
      } catch (error) {
        console.error("Error while updating balance amount:", error);
      }
    },
    showNotification(type, messageKey) {
      const messageType = type === "error" ? "danger" : "success";
      Notification.push(
        this.$t("notification." + type),
        this.$t("notification." + messageKey),
        messageType,
      );
    },
  },
  mounted() {
    // Change order currency EUR to € in the copy
    if (this.orderData.currency === "EUR") {
      this.orderData.currency = "€";
    }

    Notification.init(this);
  },
};
</script>
