<template>
  <!-- Metrix Configurator -->
  <div>
    <div id="metrix-configurator" class="m-2 border border-primary">
      <div class="page-loader">
        <div class="loading-spinner"></div>
      </div>
    </div>
  </div>
  <!-- END Metrix Configurator -->
</template>

<script>
import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";

import Swal from "sweetalert2";

// create currency object for countries de, ch, nl, kr, jp
const currency = {
  de: {
    rate: 1,
    symbol: "€",
    format: "%s %v,-",
    decimal: ",",
    thousand: ".",
    precision: 0,
  },
  ch: {
    rate: 1,
    symbol: "CHF",
    format: "%s %v,-",
    decimal: ".",
    thousand: "'",
    precision: 0,
  },
  nl: {
    rate: 1,
    symbol: "€",
    format: "%s %v,-",
    decimal: ",",
    thousand: ".",
    precision: 0,
  },
  kr: {
    rate: 1,
    symbol: "₩",
    format: "%s %v,-",
    decimal: ",",
    thousand: ".",
    precision: 0,
  },
  jp: {
    rate: 1,
    symbol: "¥",
    format: "%s %v,-",
    decimal: ",",
    thousand: ".",
    precision: 0,
  },
};

export default {
  name: "ConfiguratorMetrix",
  props: {
    orderId: {
      type: String,
      description: "Order Id",
    },
    orderBrand: {
      type: String,
      description: "Order Brand (egf, brunom, sickinger, zweiklang)",
    },
    shopId: {
      type: String,
      description: "Shop Id",
    },
    shopCountry: {
      type: String,
      description: "Shop Country (de,ch, nl, kr, jp)",
    },
    shopLanguage: {
      type: String,
      description: "Shop Language (de, en, nl, kr, ja)",
    },
    shopCurrency: {
      type: Object,
      description: "Shop Currency Data (symbol, format, decimal, ) ",
    },
    origin: {
      type: String,
      description: "Origin Name",
    },
    originId: {
      type: String,
      description: "Metrix Snapshot Id",
    },
  },
  data() {
    return {
      containerId: "",
    };
  },

  mounted() {
    Notification.init(this);

    // prouction
    let metrixEndpoint = "https://egf.services.confmetrix.com/integration.js";

    if (process.env.VUE_APP_BUILD_ENV != "production") {
      // staging and development
      metrixEndpoint = "https://egf.metrix-demo.com/integration.js";
    }

    const metrixIntegrationTag = document.getElementById("metrix-integration");

    if (metrixIntegrationTag == null) {
      const metrixIntegration = document.createElement("script");
      metrixIntegration.setAttribute("src", metrixEndpoint);
      metrixIntegration.setAttribute("id", "metrix-integration");
      document.head.appendChild(metrixIntegration);
    } else {
      console.warn("metrix-integration already exists");
    }

    if (this.origin === "metrix") {
      const snapshotId = this.originId;
      this.loadMetrixConfigurator(
        snapshotId,
        this.orderId,
        this.shopId,
        this.orderBrand,
        this.shopCountry,
        this.shopLanguage,
      );
    } else {
      console.warn("Order origin is not metrix");
    }
  },
  methods: {
    loadMetrixConfigurator(
      snapshotId,
      orderId,
      shopId,
      orderBrand,
      shopCountry,
      shopLanguage,
    ) {
      // Store the reference to the Vue component's `this`
      const vm = this;

      function init(ev) {
        let bridge = ev.detail.bridge;

        bridge.setContextValue("cartMode", "update");

        bridge.currentLanguage = "de";
        if (shopLanguage != null) {
          bridge.currentLanguage = shopLanguage;
        }

        bridge.brand = "egf";
        if (orderBrand != null) {
          bridge.brand = orderBrand;
        }

        bridge.setCurrency(currency[shopCountry]);

        var mode = bridge.integration.mode("configurator");

        if (orderBrand == "zweiklang") {
          mode = bridge.integration.mode("wavering");
        }

        bridge.integration.loadSnapshot(snapshotId, mode).then(
          containerId => {
            bridge.initContainer(mode, containerId);
            this.containerId = containerId;
          },
          reason => {
            console.log("Snapshot id cant be loaded", reason);
          },
        );

        bridge.listen("buttonClicked", ev => {
          if (ev.detail.button == "updateCart") {
            const newContainerId = ev.detail.containerId;

            Loading.dispatch("increase", 1);

            Order.updateArticles(shopId, orderId, newContainerId).then(
              result => {
                Loading.dispatch("reduce", 1);
                if (result == true) {
                  Swal.fire(
                    vm.$i18n.t("notification.success"),
                    vm.$i18n.t("notification.changesSaved"),
                    "success",
                  ).then(data => {
                    if (data.isConfirmed == true) {
                      vm.$router.push({
                        name: "OrderDetail",
                        params: {
                          shopId: shopId,
                          orderId: orderId,
                        },
                      });
                      vm.$router.go();
                    }
                  });
                } else {
                  Swal.fire(
                    vm.$i18n.t("notification.error"),
                    vm.$i18n.t("notification.changesNotSaved"),
                    "error",
                  );
                }
              },
            );
          }
        });
      }

      document.removeEventListener("metrix:configuratorInitialized", init);
      document.addEventListener("metrix:configuratorInitialized", init);

      Loading.dispatch("reduce", 1);
    },
  },
};
</script>
