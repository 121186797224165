import Fetch from "@/assets/js/Fetch";

class ManufactureShops {
  getList() {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "manufacture/1000/shops";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }
}

export default new ManufactureShops();
