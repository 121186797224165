var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:_vm.classContainer},_vm._l((_vm.nodes),function(node,index){return _c('li',{key:`node-${index}`,class:{
      'nav-main-heading': node.heading,
      'nav-main-item': !node.heading,
      open:
        node.sub && node.subActivePaths
          ? _vm.subIsActive(node.subActivePaths)
          : false,
    }},[_vm._v(" "+_vm._s(node.heading ? node.name : "")+" "),(!node.heading)?_c('router-link',{staticClass:"nav-main-link",class:node.sub ? 'nav-main-link-submenu' : '',attrs:{"to":node.to.replace(':shopid', _vm.getShopId) || '#',"active-class":node.to && node.to !== '#' ? 'active' : '',"event":node.sub ? '' : 'click'},nativeOn:{"click":function($event){return _vm.linkClicked($event, node.sub)}}},[(node.icon)?_c('i',{class:`nav-main-link-icon ${node.icon}`}):_vm._e(),(node.name)?_c('span',{staticClass:"nav-main-link-name"},[_vm._v(_vm._s(_vm.$t(node.name))+" ")]):_vm._e(),(node.badge)?_c('span',{staticClass:"nav-main-link-badge badge badge-pill badge-primary",class:node['badge-variant']
            ? `badge-${node['badge-variant']}`
            : 'badge-primary'},[_vm._v(_vm._s(node.badge))]):_vm._e()]):_vm._e(),(node.sub)?_c('base-navigation',{attrs:{"nodes":node.sub,"sub-menu":""}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }