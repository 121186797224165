<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('orders.overview.title')">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'Home',
                params: { shopid: shopId },
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{
            $t("orders.overview.title")
          }}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row>
        <b-col
          sm="12"
          xl="6"
          v-for="tableConfig in tableConfigs"
          :key="tableConfig.title"
        >
          <base-block
            :title="tableConfig.title"
            header-class="bg-primary"
            themed
          >
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="fa fa-filter"></i>
              </button>
            </template>
            <!-- Borderless Table -->
            <b-table-simple responsive borderless table-class="table-vcenter">
              <b-thead>
                <b-tr>
                  <b-th>{{ $t("orders.status.title") }}</b-th>
                  <b-th class="d-none d-sm-table-cell">
                    {{ $t("orders.status.number") }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="status in tableConfig.statuses" :key="status.key">
                  <b-td>{{ status.label }}:</b-td>
                  <b-td class="font-w600 font-size-sm">
                    <router-link
                      v-if="shopId"
                      :to="{
                        name: tableConfig.routeName,
                        params: { shopid: shopId },
                        query: { filter: status.key },
                      }"
                      >{{ orderStatus["status_" + status.key] }}</router-link
                    >
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Loading from "@/store/loading";
import OrdersList from "@/assets/js/OrdersList";

export default {
  name: "OrdersView",
  data() {
    return {
      shopId: null,
      orderStatus: {
        status_new: 0,
        status_down_payment: 0,
        status_completely_paid: 0,
        status_sent_to_supplier: 0,
        status_in_production: 0,
        status_production_finished: 0,
        status_sent_to_shop: 0,
        status_in_shop: 0,
        status_finished: 0,
        status_cancelled: 0,
      },
      tableConfigs: [
        {
          title: this.$t("orders.active.title"),
          routeName: "OrdersListActive",
          statuses: [
            { key: "created", label: this.$t("orders.status.created") },
            {
              key: "down_payment",
              label: this.$t("orders.status.down_payment"),
            },
            {
              key: "completely_paid",
              label: this.$t("orders.status.completely_paid"),
            },
            {
              key: "sent_to_supplier",
              label: this.$t("orders.status.sent_to_supplier"),
            },
            {
              key: "in_production",
              label: this.$t("orders.status.in_production"),
            },
            {
              key: "production_finished",
              label: this.$t("orders.status.production_finished"),
            },
            {
              key: "sent_to_shop",
              label: this.$t("orders.status.sent_to_shop"),
            },
            { key: "in_shop", label: this.$t("orders.status.in_shop") },
          ],
        },
        {
          title: this.$t("orders.archived.title"),
          routeName: "OrdersListArchived",
          statuses: [
            { key: "finished", label: this.$t("orders.status.finished") },
            { key: "cancelled", label: this.$t("orders.status.cancelled") },
          ],
        },
      ],
    };
  },
  methods: {
    loadData(shopId) {
      return OrdersList.getStatusOverview(shopId);
    },
    showStatus(shopId) {
      Loading.dispatch("increase", 1);

      this.loadData(shopId)
        .then(data => {
          Loading.dispatch("reduce", 1);
          this.orderStatus = data;
        })
        .catch(function(err) {
          // There was an error
          console.log("No order status data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
  },
  watch: {},
  computed: {},
  mounted() {
    this.shopId = this.$route.params.shopid;
    this.showStatus(this.shopId);
  },
};
</script>
