import Fetch from "@/assets/js/Fetch";

class ManufactureOrder {
  get(supplierId, orderId) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "manufacture/" +
        supplierId +
        "/order/" +
        orderId;

      let current_language = "de";

      Fetch.run(url, "GET", null, current_language)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
          resolve(error);
        });
    });
  }
  setStatus(supplierId, orderId, type, value) {
    return new Promise((resolve, reject) => {
      let url =
        process.env.VUE_APP_API +
        "manufacture/" +
        supplierId +
        "/order/" +
        orderId +
        "/status";

      let payload = {
        status: {
          type: type,
          value: value,
        },
      };

      Fetch.run(url, "PUT", payload).then(data => {
        if (data.ok == true) {
          resolve(data);
        } else if (data.ok == false) {
          reject(data);
        }
      });
    });
  }
}

export default new ManufactureOrder();
