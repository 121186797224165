let DbInit = function() {
  this.sendInitRequest = function() {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "init/";

      fetch(url, {
        method: "get",
      })
        .then(response => {
          response.json().then(json => {
            if (typeof json.init != "undefined") {
              resolve(true);
            }
          });
        })
        .catch(() => {
          fetch(url, {
            method: "get",
          }).then(response => {
            response.json().then(json => {
              if (typeof json.init != "undefined") {
                resolve(true);
              } else {
                resolve(false);
              }
            });
          });
        });
    });
  };
};

export default new DbInit();
