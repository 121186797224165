<template>
  <base-block title="Status" header-class="bg-primary" themed>
    <!-- STATUS for SHOP -->
    <section v-if="division == 'shop'">
      <b-row class="no-gutters">
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-1"
            class="alert px-4 py-1 rounded"
            :class="{
              'alert-secondary': order.status.sent_to_supplier == 0,
              'alert-success': order.status.sent_to_supplier == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.sent_to_supplier") }}
              <span
                v-if="order.status.sent_to_supplier == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.sent_to_supplier_date), 'long')
                "
              >
                <i class="far fa-check-circle" />
                <br />
                {{ $d(new Date(order.status.sent_to_supplier_date), "short") }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>

        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-2"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.in_production == 0,
              'alert-success': order.status.in_production == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.in_production") }}
              <span
                v-if="order.status.in_production == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.in_production_date), 'long')
                "
              >
                <i class="fa fa-hammer" />
                <br />{{
                  $d(new Date(order.status.in_production_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-3"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.production_finished == 0,
              'alert-success': order.status.production_finished == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.production_finished") }}
              <span
                v-if="order.status.production_finished == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.production_finished_date), 'long')
                "
              >
                <i class="far fa-check-circle" /><br />
                {{
                  $d(new Date(order.status.production_finished_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-4"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.sent_to_shop == 0,
              'alert-success': order.status.sent_to_shop == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.sent_to_shop") }}
              <span
                v-if="order.status.sent_to_shop == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.sent_to_shop_date), 'long')
                "
              >
                <i class="far fa-envelope" />
                <br />
                {{ $d(new Date(order.status.sent_to_shop_date), "short") }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-5"
            class="alert px-4 py-1"
            :class="{
              'alert-secondary': order.status.in_shop == 0,
              'alert-success': order.status.in_shop == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.in_shop") }}
              <span
                v-if="order.status.in_shop == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.in_shop_date), 'long')
                "
              >
                <i class="fa fa-store-alt" /><br />{{
                  $d(new Date(order.status.in_shop_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-6"
            class="alert px-4 py-1"
            :class="{
              'alert-secondary':
                order.status.finished == 0 && order.status.cancelled == 0,
              'alert-success': order.status.finished == 1,
              'alert-danger': order.status.cancelled == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              <span
                v-if="order.status.cancelled == 0 && order.status.finished == 0"
              >
                {{ $t("orders.status.finished") }} /
                {{ $t("orders.status.cancelled") }}
              </span>

              <span
                v-else-if="order.status.cancelled == 1"
                class="cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.cancelled_date), 'long')
                "
              >
                {{ $t("orders.status.cancelled") }}

                <i class="si si-close" /><br />
                <span class="small">
                  {{ $d(new Date(order.status.cancelled_date), "short") }}
                </span>
              </span>
              <span
                v-else-if="order.status.finished == 1"
                class="cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.finished_date), 'long')
                "
              >
                {{ $t("orders.status.finished") }}

                <i class="far fa-check-circle" /><br />

                <span class="small">
                  {{ $d(new Date(order.status.finished_date), "short") }}
                </span>
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
    </section>
    <!-- STATUS for MANUFACTURE -->
    <section v-else-if="division == 'manufacture'">
      <b-row class="no-gutters">
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-1"
            class="alert px-4 py-1 rounded"
            :class="{
              'alert-secondary': order.status.sent_to_supplier == 0,
              'alert-success': order.status.sent_to_supplier == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.sent_to_supplier") }}
              <span
                v-if="order.status.sent_to_supplier == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.sent_to_supplier_date), 'long')
                "
              >
                <i class="far fa-check-circle" />
                <br />
                {{ $d(new Date(order.status.sent_to_supplier_date), "short") }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>

        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-2"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.supplier_exported == 0,
              'alert-success': order.status.supplier_exported == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              Auftrag exportiert
              <span
                v-if="order.status.supplier_exported == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.supplier_exported_date), 'long')
                "
              >
                <i class="fa fa-hammer" />
                <br />{{
                  $d(new Date(order.status.supplier_exported_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>

        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-3"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.supplier_accepted == 0,
              'alert-success': order.status.supplier_accepted == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              Auftrag angenommen
              <span
                v-if="order.status.supplier_accepted == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.supplier_accepted_date), 'long')
                "
              >
                <i class="fa fa-hammer" />
                <br />{{
                  $d(new Date(order.status.supplier_accepted_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>

        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-4"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.in_production == 0,
              'alert-success': order.status.in_production == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.in_production") }}
              <span
                v-if="order.status.in_production == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.in_production_date), 'long')
                "
              >
                <i class="fa fa-hammer" />
                <br />{{
                  $d(new Date(order.status.in_production_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-5"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.production_finished == 0,
              'alert-success': order.status.production_finished == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.production_finished") }}
              <span
                v-if="order.status.production_finished == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.production_finished_date), 'long')
                "
              >
                <i class="far fa-check-circle" /><br />
                {{
                  $d(new Date(order.status.production_finished_date), "short")
                }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
        <b-col md="12" lg="2">
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            id="status-6"
            class="alert px-4  py-1"
            :class="{
              'alert-secondary': order.status.sent_to_shop == 0,
              'alert-success': order.status.sent_to_shop == 1,
            }"
          >
            <p class="mb-0 text-gray-dark text-center font-small">
              {{ $t("orders.status.sent_to_shop") }}
              <span
                v-if="order.status.sent_to_shop == 1"
                class="small cursor-pointer"
                v-b-tooltip.hover.top="
                  $d(new Date(order.status.sent_to_shop_date), 'long')
                "
              >
                <i class="far fa-envelope" />
                <br />
                {{ $d(new Date(order.status.sent_to_shop_date), "short") }}
              </span>
              <span v-else class="small">
                <i class="far fa-clock" />&nbsp;
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
    </section>
  </base-block>
</template>

<script>
export default {
  name: "OrderStatusTimeline",
  props: {
    order: {
      type: Object,
      required: true,
    },
    division: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      orderId: this.$route.params.orderid,
    };
  },
};
</script>

<style lang="scss" scoped="scoped">
#status-1,
#status-2,
#status-3,
#status-4,
#status-5,
#status-6 {
  min-height: 5em;
  display: table;
  width: 100%;

  clip-path: polygon(
    0% 0%,
    0% 85%,
    40% 85%,
    50% 100%,
    60% 85%,
    100% 85%,
    100% 0%
  );

  p {
    display: table-cell;
    vertical-align: middle;
  }
}

#status-6 {
  clip-path: none;
}

@media only screen and (min-width: 992px) {
  #status-1 {
    clip-path: polygon(
      0 0,
      calc(100% - 20px) 0,
      100% 50%,
      calc(100% - 20px) 100%,
      0 100%,
      0px 50%
    );
  }

  #status-2,
  #status-3,
  #status-4,
  #status-5 {
    clip-path: polygon(
      0 0,
      calc(100% - 20px) 0,
      100% 50%,
      calc(100% - 20px) 100%,
      0 100%,
      19px 50%
    );
  }

  #status-6 {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 19px 50%);
  }
}
</style>
