import Fetch from "@/assets/js/Fetch";

class OrdersList {
  getListActive(shopId) {
    if (typeof shopId === "undefined" || shopId == "undefined") {
      return new Promise(resolve => {
        resolve(false);
      });
    }

    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/orders?status=active";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }

  getListArchived(shopId) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/orders?status=archived";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }

  getStatusOverview(shopId) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "shop/" + shopId + "/orders/status";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }
}

export default new OrdersList();
