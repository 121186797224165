import Fetch from "@/assets/js/Fetch";

class ShoppingCartList {
  getList(shopId) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "shop/" + shopId + "/shoppingcarts/";

      Fetch.run(url).then(result => {
        resolve(result);
      });
    });
  }
}

export default new ShoppingCartList();
