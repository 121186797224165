<template>
  <div>
    <!-- Page Content -->
    <div class="content" v-if="user.username">
      <!-- Hero -->
      <base-block rounded content-full>
        <div class="py-1 text-center">
          <h1 class="mb-0 my-0 py-0">
            {{ $t("user.profile.title") }}
          </h1>
        </div>
      </base-block>
      <!-- END Hero -->
      <b-row>
        <b-col sm="12">
          <base-block
            :title="$t('user.profile.userdata')"
            header-class="bg-primary"
            themed
          >
            <b-table-simple
              responsive
              borderless
              small
              table-class="table-vcenter"
            >
              <b-tbody>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("user.profile.username") }}:
                  </b-td>
                  <b-td>
                    {{ user.username }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("user.profile.shop") }}:
                  </b-td>
                  <b-td> {{ user.shopid }} - {{ user.shopname }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <hr />
            <b-table-simple
              responsive
              borderless
              small
              table-class="table-vcenter"
            >
              <b-tbody>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("user.profile.surname") }}:
                  </b-td>
                  <b-td>
                    <span v-if="edit.userdata !== true">
                      {{ user.surname }}
                    </span>
                    <b-input-group v-else>
                      <b-form-input
                        type="text"
                        v-model="user.surname"
                      ></b-form-input>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("user.profile.forename") }}:
                  </b-td>
                  <b-td>
                    <span v-if="edit.userdata !== true">
                      {{ user.forename }}
                    </span>
                    <b-input-group v-else>
                      <b-form-input
                        type="text"
                        v-model="user.forename"
                      ></b-form-input>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 25%">
                    {{ $t("user.profile.email") }}:
                  </b-td>
                  <b-td>
                    <span v-if="edit.userdata !== true">
                      {{ user.email }}
                    </span>
                    <b-input-group v-else>
                      <b-form-input
                        type="text"
                        v-model="user.email"
                        @keyup.enter="saveInput('email')"
                      ></b-form-input>
                    </b-input-group>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-button
              v-if="edit.userdata !== true"
              @click="editUserdata()"
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-4"
            >
              <i class="far fa-edit mr-1"></i>
              {{ $t("user.profile.changeData") }}
            </b-button>
            <div v-else>
              <b-button
                @click="saveUserdata()"
                size="md"
                variant="outline-secondary"
                class="m-1 mb-4 px-4"
              >
                <i class="far fa-check-circle mr-1"></i>
                {{ $t("user.profile.saveChanges") }}
              </b-button>
              <b-button
                @click="revertUserdata()"
                size="md"
                variant="outline-secondary"
                class="m-1 mb-4 px-4"
              >
                <i class="far fa-times-circle mr-1"></i>
                {{ $t("user.profile.revertChanges") }}
              </b-button>
            </div>
          </base-block>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <base-block
            :title="$t('user.profile.changePassword')"
            header-class="bg-primary"
            themed
          >
            <b-table-simple
              responsive
              borderless
              small
              table-class="table-vcenter"
            >
              <b-tbody>
                <b-tr>
                  <b-td class="font-w600" style="width: 30%">
                    {{ $t("user.profile.currentPassword") }}:
                  </b-td>
                  <b-td>
                    <b-input-group>
                      <b-form-input
                        type="password"
                        v-model="password.current"
                      ></b-form-input>
                    </b-input-group>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 30%">
                    {{ $t("user.profile.newPassword") }}:
                  </b-td>
                  <b-td>
                    <b-input-group>
                      <b-form-input
                        type="password"
                        v-model="password.new"
                      ></b-form-input>
                    </b-input-group>
                    <div
                      class="mt-1"
                      v-if="this.score != null && password.new.length > 4"
                    >
                      {{ $t("user.profile.passwordStrength[" + score + "]") }}
                    </div>
                    <password-meter :password="password.new" @score="onScore" />
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td class="font-w600" style="width: 30%">
                    {{ $t("user.profile.newPasswordConfirm") }}:
                  </b-td>
                  <b-td>
                    <b-input-group>
                      <b-form-input
                        type="password"
                        v-model="password.newVerify"
                      ></b-form-input>
                    </b-input-group>
                    <div
                      class="mt-1 text-red"
                      v-if="
                        password.newVerify.length > 4 &&
                          password.new != password.newVerify
                      "
                    >
                      {{ $t("user.profile.passwordNoMatch") }}
                    </div>
                    <div
                      class="mt-1 text-green"
                      v-if="
                        password.new.length > 4 &&
                          password.newVerify.length > 4 &&
                          password.new == password.newVerify
                      "
                    >
                      {{ $t("user.profile.passwordMatch") }}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-button
              @click="saveNewPassword()"
              :disabled="saveNewPasswordDisabled"
              size="md"
              variant="outline-secondary"
              class="m-1 mb-4 px-4"
            >
              <i class="far fa-check-circle mr-1"></i>
              {{ $t("user.profile.checkAndSave") }}
            </b-button>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Loading from "@/store/loading";
import AuthStore from "@/store/auth";

import Notification from "@/assets/js/Notification";
import User from "@/assets/js/User";

import passwordMeter from "vue-simple-password-meter";

export default {
  name: "UserProfile",
  components: { passwordMeter },
  data() {
    return {
      isInit: false,
      layout: {
        showUser: true,
        showPassword: true,
      },
      user: {
        username: "",
        surname: "",
        forename: "",
        email: "",
      },
      edit: {
        userdata: false,
      },
      revertValues: {
        surname: "",
        forename: "",
        email: "",
      },
      password: {
        current: "",
        new: "",
        newVerify: "",
      },
      score: null,
      strength: null,
    };
  },
  mounted() {
    Notification.init(this);
    this.init();
  },
  methods: {
    init() {
      if (this.isInit == false) {
        this.user.username = AuthStore.getters.username;
        if (this.user.username != "") {
          this.show(this.user.username);
          this.isInit = true;
          Loading.dispatch("reduce", 1);
        } else {
          setTimeout(() => this.init(), 1000);
        }
      }
    },
    loadData(username) {
      return User.get(username);
    },
    show(username) {
      Loading.dispatch("increase", 1);

      this.loadData(username)
        .then(data => {
          Loading.dispatch("reduce", 1);
          this.user = data;
        })
        .catch(function(err) {
          // There was an error
          console.error("No user data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    editUserdata() {
      this.revertValues["surname"] = this.user["surname"];
      this.revertValues["forename"] = this.user["forename"];
      this.revertValues["email"] = this.user["email"];
      this.edit.userdata = true;
    },
    saveUserdata() {
      User.update(this.user.username, this.user)
        .then(result => {
          if (result === false) {
            Notification.push(
              this.$t("notification.error"),
              this.$t("user.notification.notChanged"),
              "danger",
            );
            Loading.dispatch("reduce");
          } else {
            Notification.push(
              this.$t("notification.success"),
              this.$t("user.notification.changed"),
              "success",
            );
            this.edit.userdata = false;
            Loading.dispatch("reduce");
          }
        })
        .catch(function(err) {
          // There was an error
          console.error("User Update not successful", err);
          Loading.dispatch("reduce", 1);
        });
    },
    revertUserdata() {
      this.user["surname"] = this.revertValues["surname"];
      this.user["forename"] = this.revertValues["forename"];
      this.user["email"] = this.revertValues["email"];
      this.edit.userdata = false;
    },
    saveNewPassword() {
      User.updatePassword(this.user.username, this.password)
        .then(result => {
          if (result === false) {
            Notification.push(
              this.$t("notification.error"),
              this.$t("user.notification.notChangedPassword"),
              "danger",
            );
            Loading.dispatch("reduce");
          } else {
            Notification.push(
              this.$t("notification.success"),
              this.$t("user.notification.changedPassword"),
              "success",
            );
            this.password.current = "";
            this.password.new = "";
            this.password.newVerify = "";
            Loading.dispatch("reduce");
          }
        })
        .catch(function(err) {
          // There was an error
          console.error("Password Update not successful", err);
          Loading.dispatch("reduce");
        });
    },
    onScore({ score, strength }) {
      // console.log(score); // from 0 to 4
      // console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      this.score = score;
      this.strength = strength;
    },
  },
  watch: {},
  computed: {
    shopName() {
      return "";
    },
    saveNewPasswordDisabled() {
      if (
        this.password.current.length >= 6 &&
        this.password.new.length >= 8 &&
        this.password.newVerify.length >= 8 &&
        this.score >= 2 &&
        this.password.new == this.password.newVerify &&
        this.password.current != this.password.new
      ) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
// Flatpickr + Custom overrides
@import "~flatpickr/dist/flatpickr.css";
@import "./src/assets/scss/vendor/flatpickr";
</style>
