import Fetch from "@/assets/js/Fetch";

class OrdersSearch {
  getSearchResult(shopId, searchString) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "shop/" +
        shopId +
        "/orders/search?search=" +
        searchString;

      Fetch.run(url)
        .then(result => {
          resolve(result);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }
}

export default new OrdersSearch();
