import Fetch from "@/assets/js/Fetch";

class ManufactureOrdersSearch {
  getSearchResult(searchString) {
    return new Promise(resolve => {
      const supplierId = 1000;

      let url =
        process.env.VUE_APP_API +
        "manufacture/" +
        supplierId +
        "/orders/search?search=" +
        searchString;

      Fetch.run(url)
        .then(result => {
          resolve(result);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }
}

export default new ManufactureOrdersSearch();
