import Fetch from "@/assets/js/Fetch";

class ShoppingCart {
  get(timestamp, shopId) {
    return new Promise(resolve => {
      const url =
        process.env.VUE_APP_API +
        "shop" +
        "/" +
        shopId +
        "/" +
        "shoppingcart" +
        "/" +
        timestamp +
        "/";

      Fetch.run(url)
        .then(result => {
          if (result.timestamp) {
            resolve(result);
          } else {
            resolve(false);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }

  createOrder(shopId, formData) {
    return new Promise(resolve => {
      const url = process.env.VUE_APP_API + "shop/" + shopId + "/order";

      Fetch.run(url, "POST", formData)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new ShoppingCart();
