<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('orderCreateSample.title') + ' ' + orderId">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'Home',
                params: { shopid: shopId },
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'OrderDetail',
                params: { shopid: shopId, orderid: orderId },
              }"
              >{{ $t("layout.breadcrumb.order") }} {{ orderId }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{
            $t("orders.active.title")
          }}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>

    <div class="content" v-if="isOrderIdValid">
      <b-row>
        <b-col sm="12" v-for="(item, itemKey) in order.items" :key="itemKey">
          <base-block :title="item.name" header-class="bg-primary" themed>
            <b-row>
              <b-col sm="8">
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td class="font-w600" style="width: 35%">
                        {{ $t("orderCreateSample.articlenumber") }}:
                        {{ item.item_number }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="font-w600">
                        {{ $t("orderCreateSample.size") }}:
                        {{ item.details.size }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <hr class="mt-1 mb-2" />
                        <span class="font-w600"
                          >{{
                            $t("orderCreateSample.price.default.sample")
                          }}:</span
                        ><br />
                        {{
                          $t(
                            "orderCreateSample.price.default.sampleDescription",
                          )
                        }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <span class="font-w600"
                          >{{
                            $t("orderCreateSample.price.default.price")
                          }}:</span
                        ><br />
                        € 40,-
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col sm="4">
                <b-img
                  fluid
                  rounded
                  class="py-1 px-4"
                  style="border: 1px solid #ededed"
                  :src="item.details.image[0]"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  :id="'check-defaultSample-' + item.item_id"
                  v-model="item.defaultSample"
                  :name="'checkbox-' + item.item_id"
                  value="1"
                  unchecked-value="0"
                  class="py-2"
                >
                  {{ $t("orderCreateSample.checkSample") }}
                </b-form-checkbox>

                <b-form-checkbox
                  :id="'check-deviations-' + item.item_id"
                  v-model="item.deviations"
                  value="1"
                  unchecked-value="0"
                  class="py-2"
                  :disabled="!item.defaultSample || item.defaultSample == 0"
                >
                  {{ $t("orderCreateSample.checkDeviations") }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="item.deviations && item.deviations == 1">
              <b-col lg="6">
                <b-form-textarea
                  rows="5"
                  :placeholder="$t('orderCreateSample.deviationsDescription')"
                  v-model="item.deviationsDescription"
                  class="py-2 mt-2 mb-4"
                ></b-form-textarea>
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-tbody>
                    <b-tr>
                      <b-td class="w-50 text-right" style="">
                        Aufpreis in €
                      </b-td>
                      <b-td>
                        <b-form-input
                          type="number"
                          v-model="item.extraCharge"
                          maxlength="10"
                        ></b-form-input>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col lg="6">
                <b-table-simple
                  responsive
                  borderless
                  small
                  table-class="table-vcenter"
                >
                  <b-thead>
                    <b-tr class="font-w600">
                      <b-td>Abweichung</b-td>
                      <b-td>Aufpreis</b-td>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.stones")
                      }}</b-td>
                      <b-td>€ 10,-</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.stonesFake")
                      }}</b-td>
                      <b-td>€ 10,-</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.stonesFakeMore")
                      }}</b-td>
                      <b-td>€ 20,-</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.ringWidth")
                      }}</b-td>
                      <b-td>€ 5,-/mm</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.grooves")
                      }}</b-td>
                      <b-td>€ 20,-</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{
                        $t("orderCreateSample.price.extraCharge.finish")
                      }}</b-td>
                      <b-td>€ 20,-</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
      <base-block title="Bestellung" header-class="bg-primary" themed>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="customerPickupDate">
              <template #label>
                {{ $t("orderCreateSample.formData.customerPickupDate") }}
                <span class="text-danger">*</span>
              </template>
              <flat-pickr
                id="customerPickupDate"
                class="form-control bg-white"
                placeholder="Abholtermin Kunde auswählen"
                v-model="customerPickupDate"
                :config="flatPickrConfig"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="salesperson">
              <template #label>
                {{ $t("orderCreateSample.formData.salesperson") }}
              </template>
              <b-form-input
                v-model="order.salesperson"
                id="salesperson"
                class="form-control bg-white"
                type="text"
                name="salesperson"
                maxlength="25"
                :placeholder="
                  $t('orderCreateSample.formData.salespersonPlaceholder')
                "
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              @click="createSampleOrder()"
              size="lg"
              variant="alt-success"
              class="btn-rounded px-6 mr-2 mb-4"
              :disabled="isCreateButtonDisabled"
            >
              <i class="si si-rocket mr-1"></i>
              {{ $t("orderCreateSample.buttonCreate") }}
            </b-button>
          </b-col>
        </b-row>
      </base-block>
    </div>
  </section>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { English } from "flatpickr/dist/l10n/default.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import OrderStore from "@/store/order";

import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";

export default {
  name: "OrderCreateSample",
  components: {
    flatPickr,
  },
  data() {
    return {
      // Get more form https://flatpickr.js.org/options/
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d", // Date Format displayed in the input field
        altInput: true,
        dateFormat: "Y-m-d", // Date Format used for submitting
        locale: English,
      },
      isOrderIdValid: false,
      orderId: "",
      shopId: "",
      order: {
        order_year: 0,
        order_month: 0,
        order_id: 0,
        order_type: "A",
        shop_id: 0,
        type: "",
        delivery_date_customer: "2021-01-01",
        salesperson: "",
        items: [
          {
            item_id: 0,
            image: [],
            defaultSample: 0,
            checkDeviations: 0,
            deviations: "",
          },
        ],
      },
    };
  },
  methods: {
    loadData(shopId, orderId) {
      return Order.get(shopId, orderId);
    },
    show(shopId, orderId) {
      Loading.dispatch("increase", 1);

      this.loadData(shopId, orderId)
        .then(data => {
          Loading.dispatch("reduce", 1);
          this.order = data.order;
          this.isOrderIdValid = true;
        })
        .catch(function(err) {
          // There was an error
          console.log("No order data available.", err);
          Loading.dispatch("reduce", 1);
        });
    },
    setCustomerPickupDate() {
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 14);
      let proposalCustomerPickupDate = dateObj.toISOString().slice(0, 10);
      this.customerPickupDate = proposalCustomerPickupDate;
    },
    validateInput() {
      return true;
    },
    createSampleOrder() {
      // Show Loader
      Loading.dispatch("increase", 1);

      // validate important form data
      if (this.validateInput() === false) {
        return false;
      }

      // collect form data for order
      const sampleOrderData = {
        shopId: this.shopId,
        orderType: this.order.order_type,
        orderYear: this.order.order_year,
        orderMonth: this.order.order_month,
        orderId: this.order.order_id,

        commission: this.order.commission,
        customerPickupDate: this.customerPickupDate,
        salesperson: this.order.salesperson,
        exchangeRate: this.order.exchange_rate,
        vat: this.order.vat,
        currency: this.order.currency,

        origin: this.order.origin,
        origin_id: this.order.origin_id,

        items: [],
      };

      const items = [];

      for (const [key, value] of Object.entries(this.order.items)) {
        if (
          typeof value.defaultSample == "undefined" ||
          value.defaultSample == "0"
        ) {
          continue;
        }

        const tmp = {
          itemnumber: value.item_number,
          size: value.size,
          name: value.name,
          defaultSample: value.defaultSample,
          deviations: value.deviations,
          deviationsDescription: value.deviationsDescription,
          extraCharge: value.extraCharge,
        };

        items[key] = tmp;
      }

      sampleOrderData["items"] = items;
      // save form data in Store
      OrderStore.dispatch("setSampleOrderData", sampleOrderData);

      // send order directly to backend
      Order.createSampleOrder(this.shopId, sampleOrderData).then(result => {
        if (result.ok === true) {
          let redirectUrl =
            "/" + result.shop_id + "/orders/detail/" + result.order_id;
          this.$router.push(redirectUrl);
        } else {
          alert("Error - Bitte wenden Sie sich an den Administrator");
        }
      });
    },
    reinitializeFlatpickr() {
      if (this.$refs.flatPickr) {
        const flatpickrInstance = this.$refs.flatPickr.flatpickr;
        if (flatpickrInstance) {
          flatpickrInstance.destroy();
          this.$refs.flatPickr.flatpickr(this.flatPickrConfig);
        }
      }
    },
  },
  watch: {
    "$i18n.locale": {
      handler(newLocale) {
        const locales = {
          de: German,
          nl: Dutch,
          fr: French,
          ja: Japanese,
          ko: Korean,
          en: English,
        };
        this.flatPickrConfig.locale = locales[newLocale] || English;

        switch (newLocale) {
          case "de":
            this.flatPickrConfig.altFormat = "d.m.Y";
            break;
          case "nl":
            this.flatPickrConfig.altFormat = "d-m-Y";
            break;
          case "fr":
            this.flatPickrConfig.altFormat = "d/m/Y";
            break;
          case "ja":
            this.flatPickrConfig.altFormat = "Y/m/d";
            break;
          case "ko":
            this.flatPickrConfig.altFormat = "Y.m.d";
            break;
          case "en":
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
          default:
            this.flatPickrConfig.altFormat = "Y-m-d";
            break;
        }

        this.$nextTick(() => {
          this.reinitializeFlatpickr();
        });
      },
      immediate: true,
    },
  },
  computed: {
    isCreateButtonDisabled() {
      let status = true;

      for (const [key, value] of Object.entries(this.order.items)) {
        if (key !== null) {
          if (
            (typeof value.defaultSample !== "undefined" &&
              value.defaultSample == 1) ||
            (typeof value.checkDeviations !== "undefined" &&
              value.checkDeviations == 1)
          ) {
            status = false;
          }
        }
      }

      return status;
    },
  },
  mounted() {
    this.shopId = this.$route.params.shopid;
    this.orderId = this.$route.params.orderid;

    Notification.init(this);

    this.show(this.shopId, this.orderId);

    this.setCustomerPickupDate();
  },
};
</script>
