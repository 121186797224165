<template>
  <base-block
    :title="$t('order.prints.title')"
    header-class="bg-primary"
    themed
  >
    <!-- Prints for SHOP -->
    <section v-if="division == 'shop'">
      <div class="mb-4">
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="downloadShopOrderPdf()"
        >
          <i class="fa fa-download mr-1"></i>
          {{ $t("order.prints.downloadOrderConfirmation") }}
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="showShopOrderPdf()"
        >
          <i class="fa fa-print mr-1"></i>
          {{ $t("order.prints.openOrderConfirmation") }}
        </b-button>
      </div>
    </section>
    <!-- Prints for  egfArchive -->
    <section v-if="division == 'egfArchive'">
      <div class="mb-4">
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="showEgfArchiveOrderPdf()"
        >
          <i class="fa fa-print mr-1"></i>
          {{ $t("order.prints.openOrderConfirmation") }}
        </b-button>
      </div>
    </section>
    <!-- Prints for MANUFACTURE -->
    <section v-else-if="division == 'manufacture'">
      <div class="mb-4">
        <b-button
          size="md"
          variant="outline-secondary"
          class="m-1"
          @click="showManufactureOrderPdf()"
        >
          <i class="fa fa-print mr-1"></i> Produktions-Übersicht PDF (Admin)
        </b-button>
      </div>
    </section>
  </base-block>
</template>

<script>
import OrderConfirmation from "@/assets/js/OrderConfirmation";
import ManufactureOrderPdf from "@/assets/js/ManufactureOrderPdf";

import Loading from "@/store/loading";

export default {
  name: "OrderPrints",
  props: {
    order: {
      type: Object,
      required: true,
    },
    division: {
      /* possible values: shop, manufacture */
      type: String,
      required: true,
    },
  },

  methods: {
    downloadShopOrderPdf() {
      Loading.dispatch("increase", 1);

      OrderConfirmation.openPdf(
        this.shopId,
        this.orderId,
        this.$i18n.locale,
      ).then(data => {
        if (data.ok == true) {
          let url = data.url;
          this.pdfUrl = url;

          let link = document.createElement("a");
          link.href = url;
          link.download = this.orderId + ".pdf";
          link.click();
          Loading.dispatch("reduce", 1);

          this.$swal(
            this.$t("notification.success"),
            this.$t("notification.successFileDownload"),
            "success",
          );
        }
      });
    },

    showShopOrderPdf() {
      OrderConfirmation.openPdf(
        this.shopId,
        this.orderId,
        this.$i18n.locale,
        "inline",
      ).then(data => {
        if (data.ok == true) {
          let url = data.url;
          window.open(url);
        }
      });
    },

    showEgfArchiveOrderPdf() {
      OrderConfirmation.openPdf(
        this.shopId,
        this.orderId,
        this.$i18n.locale,
        "inline",
      ).then(data => {
        if (data.status == "success") {
          const base64String = data.pdf;

          // create blob object from base64 string
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });

          // create object url from blob
          const blobUrl = URL.createObjectURL(blob);

          // display pdf in new tab
          window.open(blobUrl);
        }
      });
    },

    showManufactureOrderPdf() {
      ManufactureOrderPdf.openPdf(
        this.supplierId,
        this.orderId,
        this.$i18n.locale,
        "inline",
      ).then(data => {
        if (data.ok == true) {
          let url = data.url;
          window.open(url);
        }
      });
    },
  },

  data() {
    return {
      supplierId: 1000,
      orderId: this.$route.params.orderid,
      shopId: this.$route.params.shopid,
    };
  },

  mounted() {
    this.shopId = this.$route.params.shopid;
    this.orderId = this.$route.params.orderid;
  },
};
</script>
