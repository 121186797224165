var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-block',{attrs:{"title":"Status","header-class":"bg-primary","themed":""}},[(_vm.division == 'shop')?_c('section',[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1 rounded",class:{
            'alert-secondary': _vm.order.status.sent_to_supplier == 0,
            'alert-success': _vm.order.status.sent_to_supplier == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-1"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.sent_to_supplier"))+" "),(_vm.order.status.sent_to_supplier == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.sent_to_supplier_date), 'long')
              ),expression:"\n                $d(new Date(order.status.sent_to_supplier_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-check-circle"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.sent_to_supplier_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.in_production == 0,
            'alert-success': _vm.order.status.in_production == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-2"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.in_production"))+" "),(_vm.order.status.in_production == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.in_production_date), 'long')
              ),expression:"\n                $d(new Date(order.status.in_production_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"fa fa-hammer"}),_c('br'),_vm._v(_vm._s(_vm.$d(new Date(_vm.order.status.in_production_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.production_finished == 0,
            'alert-success': _vm.order.status.production_finished == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-3"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.production_finished"))+" "),(_vm.order.status.production_finished == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.production_finished_date), 'long')
              ),expression:"\n                $d(new Date(order.status.production_finished_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-check-circle"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.production_finished_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.sent_to_shop == 0,
            'alert-success': _vm.order.status.sent_to_shop == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-4"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.sent_to_shop"))+" "),(_vm.order.status.sent_to_shop == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.sent_to_shop_date), 'long')
              ),expression:"\n                $d(new Date(order.status.sent_to_shop_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-envelope"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.sent_to_shop_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.in_shop == 0,
            'alert-success': _vm.order.status.in_shop == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-5"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.in_shop"))+" "),(_vm.order.status.in_shop == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.in_shop_date), 'long')
              ),expression:"\n                $d(new Date(order.status.in_shop_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"fa fa-store-alt"}),_c('br'),_vm._v(_vm._s(_vm.$d(new Date(_vm.order.status.in_shop_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary':
              _vm.order.status.finished == 0 && _vm.order.status.cancelled == 0,
            'alert-success': _vm.order.status.finished == 1,
            'alert-danger': _vm.order.status.cancelled == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-6"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[(_vm.order.status.cancelled == 0 && _vm.order.status.finished == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.status.finished"))+" / "+_vm._s(_vm.$t("orders.status.cancelled"))+" ")]):(_vm.order.status.cancelled == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.cancelled_date), 'long')
              ),expression:"\n                $d(new Date(order.status.cancelled_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.cancelled"))+" "),_c('i',{staticClass:"si si-close"}),_c('br'),_c('span',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.cancelled_date), "short"))+" ")])]):(_vm.order.status.finished == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.finished_date), 'long')
              ),expression:"\n                $d(new Date(order.status.finished_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.finished"))+" "),_c('i',{staticClass:"far fa-check-circle"}),_c('br'),_c('span',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.finished_date), "short"))+" ")])]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])])],1)],1):(_vm.division == 'manufacture')?_c('section',[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1 rounded",class:{
            'alert-secondary': _vm.order.status.sent_to_supplier == 0,
            'alert-success': _vm.order.status.sent_to_supplier == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-1"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.sent_to_supplier"))+" "),(_vm.order.status.sent_to_supplier == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.sent_to_supplier_date), 'long')
              ),expression:"\n                $d(new Date(order.status.sent_to_supplier_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-check-circle"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.sent_to_supplier_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.supplier_exported == 0,
            'alert-success': _vm.order.status.supplier_exported == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-2"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" Auftrag exportiert "),(_vm.order.status.supplier_exported == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.supplier_exported_date), 'long')
              ),expression:"\n                $d(new Date(order.status.supplier_exported_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"fa fa-hammer"}),_c('br'),_vm._v(_vm._s(_vm.$d(new Date(_vm.order.status.supplier_exported_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.supplier_accepted == 0,
            'alert-success': _vm.order.status.supplier_accepted == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-3"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" Auftrag angenommen "),(_vm.order.status.supplier_accepted == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.supplier_accepted_date), 'long')
              ),expression:"\n                $d(new Date(order.status.supplier_accepted_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"fa fa-hammer"}),_c('br'),_vm._v(_vm._s(_vm.$d(new Date(_vm.order.status.supplier_accepted_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.in_production == 0,
            'alert-success': _vm.order.status.in_production == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-4"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.in_production"))+" "),(_vm.order.status.in_production == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.in_production_date), 'long')
              ),expression:"\n                $d(new Date(order.status.in_production_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"fa fa-hammer"}),_c('br'),_vm._v(_vm._s(_vm.$d(new Date(_vm.order.status.in_production_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.production_finished == 0,
            'alert-success': _vm.order.status.production_finished == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-5"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.production_finished"))+" "),(_vm.order.status.production_finished == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.production_finished_date), 'long')
              ),expression:"\n                $d(new Date(order.status.production_finished_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-check-circle"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.production_finished_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])]),_c('b-col',{attrs:{"md":"12","lg":"2"}},[_c('div',{staticClass:"alert px-4 py-1",class:{
            'alert-secondary': _vm.order.status.sent_to_shop == 0,
            'alert-success': _vm.order.status.sent_to_shop == 1,
          },attrs:{"role":"alert","aria-live":"polite","aria-atomic":"true","id":"status-6"}},[_c('p',{staticClass:"mb-0 text-gray-dark text-center font-small"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.sent_to_shop"))+" "),(_vm.order.status.sent_to_shop == 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.$d(new Date(_vm.order.status.sent_to_shop_date), 'long')
              ),expression:"\n                $d(new Date(order.status.sent_to_shop_date), 'long')\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"small cursor-pointer"},[_c('i',{staticClass:"far fa-envelope"}),_c('br'),_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.order.status.sent_to_shop_date), "short"))+" ")]):_c('span',{staticClass:"small"},[_c('i',{staticClass:"far fa-clock"}),_vm._v("  ")])])])])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }