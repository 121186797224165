import Fetch from "@/assets/js/Fetch";

class OrderItem {
  update(shopId, orderId, itemId, key, value) {
    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API + "shop/" + shopId + "/order/" + orderId;

      let payload = {
        item: {
          item_id: itemId,
          key: key,
          value: value,
        },
      };

      Fetch.run(url, "PUT", payload)
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new OrderItem();
