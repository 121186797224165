<template>
  <section>
    <!-- Hero -->
    <base-page-heading
      :title="$t('order.changeArticles.button') + ' - ' + orderId"
    />
    <!-- END Hero -->

    <!-- Page Content -->
    <ConfiguratorMetrix
      v-if="loadConfiguratorMetrix === true"
      :orderId="orderId"
      :shopId="shopId"
      :origin="origin"
      :originId="originId"
      :orderBrand="orderBrand"
      :shopCountry="shopCountry"
      :shopLanguage="shopLanguage"
    />
    <!-- END Page Content -->
  </section>
</template>

<script>
import ConfiguratorMetrix from "@/components/ConfiguratorMetrix.vue";

import Loading from "@/store/loading";
import Notification from "@/assets/js/Notification";
import Order from "@/assets/js/Order";

export default {
  name: "OrderConfigurator",
  components: {
    ConfiguratorMetrix,
  },
  data() {
    return {
      loadConfiguratorMetrix: false,
      shopId: "",
      orderId: "",
      orderBrand: "",
      origin: "",
      originId: "",
      shopCountry: "",
      shopLanguage: "",
    };
  },
  methods: {},
  watch: {},
  computed: {
    VUE_APP_BUILD_ENV() {
      return process.env.VUE_APP_BUILD_ENV;
    },
  },
  mounted() {
    Loading.dispatch("increase", 1);

    this.shopId = this.$route.params.shopid;
    this.orderId = this.$route.params.orderid;

    Order.get(this.shopId, this.orderId).then(data => {
      this.origin = data.order.origin;
      this.originId = data.order.origin_id;

      this.orderBrand = data.order.brand_name;

      this.shopCountry = data.shop_country;
      this.shopLanguage = data.shop_language;

      if (this.origin == "metrix") {
        this.loadConfiguratorMetrix = true;
      } else {
        this.loadConfiguratorMetrix = false;
      }
    });

    Notification.init(this);
  },
};
</script>
