import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serviceOrderData: "",
    sampleOrderData: "",
  },
  mutations: {
    setServiceOrderData(state, serviceOrderData) {
      state.serviceOrderData = serviceOrderData;
    },
    setSampleOrderData(state, sampleOrderData) {
      state.sampleOrderData = sampleOrderData;
    },
  },
  actions: {
    setServiceOrderData(context, serviceOrderData) {
      context.commit("setServiceOrderData", serviceOrderData);
    },
    setSampleOrderData(context, sampleOrderData) {
      context.commit("setSampleOrderData", sampleOrderData);
    },
  },
  modules: {},
  getters: {
    serviceOrderData: state => {
      return state.serviceOrderData;
    },
    sampleOrderData: state => {
      return state.sampleOrderData;
    },
  },
});
