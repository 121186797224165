var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('base-page-heading',{attrs:{"title":_vm.$t('orders.overview.title')},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('b-breadcrumb',{staticClass:"breadcrumb-alt"},[_c('b-breadcrumb-item',[(_vm.shopId)?_c('router-link',{attrs:{"to":{
              name: 'Home',
              params: { shopid: _vm.shopId },
            }}},[_vm._v(_vm._s(_vm.$t("layout.breadcrumb.home")))]):_vm._e()],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t("orders.overview.title")))])],1)]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('b-row',_vm._l((_vm.tableConfigs),function(tableConfig){return _c('b-col',{key:tableConfig.title,attrs:{"sm":"12","xl":"6"}},[_c('base-block',{attrs:{"title":tableConfig.title,"header-class":"bg-primary","themed":""},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('button',{staticClass:"btn-block-option",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-filter"})])]},proxy:true}],null,true)},[_c('b-table-simple',{attrs:{"responsive":"","borderless":"","table-class":"table-vcenter"}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v(_vm._s(_vm.$t("orders.status.title")))]),_c('b-th',{staticClass:"d-none d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.$t("orders.status.number"))+" ")])],1)],1),_c('b-tbody',_vm._l((tableConfig.statuses),function(status){return _c('b-tr',{key:status.key},[_c('b-td',[_vm._v(_vm._s(status.label)+":")]),_c('b-td',{staticClass:"font-w600 font-size-sm"},[(_vm.shopId)?_c('router-link',{attrs:{"to":{
                      name: tableConfig.routeName,
                      params: { shopid: _vm.shopId },
                      query: { filter: status.key },
                    }}},[_vm._v(_vm._s(_vm.orderStatus["status_" + status.key]))]):_vm._e()],1)],1)}),1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }