<template>
  <span class="locale-changer">
    <select v-model="$i18n.locale" @change="changeLanguage()">
      <option
        v-for="locale in $i18n.availableLocales"
        :key="`locale-${locale}`"
        :value="locale"
        >{{ languageNames[locale] }} ({{ locale.toUpperCase() }})</option
      >
    </select>
  </span>
</template>

<script>
export default {
  name: "LocaleChanger",
  methods: {
    changeLanguage() {
      this.$cookies.set("egfb2b-language", this.$i18n.locale);
    },
  },
  data() {
    return {
      languageNames: {
        en: "English",
        de: "German",
        nl: "Dutch",
        fr: "French",
        ko: "Korean",
        ja: "Japanese",
      },
    };
  },
};
</script>
