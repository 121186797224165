import Fetch from "@/assets/js/Fetch";

class ManufactureOrderPdf {
  openPdf(supplierId, orderId, language, contentDisposition = "attachment") {
    if (contentDisposition !== "inline") {
      contentDisposition = "attachment";
    }

    return new Promise(resolve => {
      let url =
        process.env.VUE_APP_API +
        "manufacture/" +
        supplierId +
        "/order/" +
        orderId +
        "/pdf";

      Fetch.run(url, "GET")
        .then(result => {
          resolve(result);
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  }
}

export default new ManufactureOrderPdf();
