<template>
  <div>
    <OrderB2B v-if="origin == 'egfB2B'" :order-data="orderData" />
    <OrderEgfArchive
      v-else-if="origin == 'egfArchive'"
      :order-data="orderData"
    />
    <div v-else>
      <div v-if="!isOrderIdValid">Loading...</div>
      <div v-else>Order not found.</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/composables";
import OrderB2B from "@/components/OrderB2B.vue";
import OrderEgfArchive from "@/components/OrderEgfArchive.vue";
import Order from "@/assets/js/Order";

const origin = ref("");
const shopId = ref(0);
const orderId = ref(0);
const orderData = ref({});
const isOrderIdValid = ref(false);

const loadData = async (shopId, orderId) => {
  return Order.get(shopId, orderId);
};

const route = useRoute();

onMounted(async () => {
  shopId.value = route.params.shopid;
  orderId.value = route.params.orderid;

  try {
    const data = await loadData(shopId.value, orderId.value);

    origin.value = data.origin; // egfB2B || egfArchive
    orderData.value = data;
    isOrderIdValid.value = true;
  } catch (err) {
    console.error("No order data available.", err);
  }
});
</script>
