<template>
  <base-block
    :title="order.service_description.type[order.service_type]"
    header-class="bg-primary"
    themed
  >
    <b-table-simple responsive borderless small table-class="table-vcenter">
      <b-tbody>
        <b-tr>
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.orderServiceHeader.type") }}:
          </b-td>
          <b-td>
            <span>
              {{ order.service_description.type[order.service_type] }}
            </span>
          </b-td>
        </b-tr>
        <b-tr v-if="order.service_type == 1">
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.orderServiceHeader.serviceProcessing") }}:
          </b-td>
          <b-td>
            <span>
              {{
                order.service_description.processing[order.service_processing]
              }}
            </span>
          </b-td>
        </b-tr>
        <b-tr v-if="order.service_type == 2">
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.orderServiceHeader.reasonForComplaint") }}:
          </b-td>
          <b-td>
            <span>
              {{ order.service_description.reason[order.service_reason] }}
            </span>
          </b-td>
        </b-tr>
        <b-tr v-if="order.service_type == 2">
          <b-td class="font-w600" style="width: 25%">
            {{ $t("order.orderServiceHeader.complaintsProcessing") }}:
          </b-td>
          <b-td>
            <span>
              {{
                order.service_description.processing[order.service_processing]
              }}
            </span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </base-block>
</template>

<script>
export default {
  name: "OrderServiceHeader",
  props: {
    order: {
      type: Object,
      description: "",
    },
  },
};
</script>
