<template>
  <section>
    <!-- Hero -->
    <base-page-heading :title="$t('report.title')">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item>
            <router-link
              v-if="shopId"
              :to="{
                name: 'Home',
                params: { shopid: shopId },
              }"
              >{{ $t("layout.breadcrumb.home") }}</router-link
            >
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{ $t("report.title") }}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row>
        <b-col sm="12" xl="6">
          <base-block title="Report 1" header-class="bg-primary-dark" themed>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <p>
              Hier könnte ihr Report stehen
            </p>
          </base-block>
        </b-col>
        <b-col sm="12" xl="6">
          <base-block title="Report 2" header-class="bg-primary-dark" themed>
            <template #options>
              <button type="button" class="btn-block-option">
                <i class="si si-settings"></i>
              </button>
            </template>
            <p>
              Hier könnte ein weiterer Report stehen
            </p>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
/*
import Loading from "@/store/loading";
import Report from "@/assets/js/Report";
import Notification from "@/assets/js/Notification";
import ChartjsLine from "@/components/Chartjs/Line";
import ChartjsBar from "@/components/Chartjs/Bar";
import i18n from "@/i18n";
*/
export default {
  name: "ReportView",

  data() {
    return {
      shopId: null,
    };
  },
  mounted() {
    this.shopId = this.$route.params.shopid;
  },
  methods: {},
  watch: {},
  computed: {},
};
</script>

<style lang="scss" scoped="scoped">
.test {
  background: #ffffff;
}
</style>
