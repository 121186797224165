/*
 * Main navigation array
 */

export default {
  main: [
    {
      name: "menu.dashboard.title",
      to: "/:shopid",
      icon: "si si-speedometer",
    },
    {
      name: "menu.orders.title",
      to: "/:shopid/orders",
      icon: "fa fa-book",
      sub: [
        {
          name: "menu.orders.overview",
          to: "/:shopid/orders/overview",
        },
        {
          name: "menu.orders.active",
          to: "/:shopid/orders/active",
        },
        {
          name: "menu.orders.archived",
          to: "/:shopid/orders/archived",
        },
        /*
        {
          name: "Neue",
          to: "/:shopid/orders/created"
        },
        {
          name: "Angezahlte",
          to: "/:shopid/orders/down_payment"
        },
        {
          name: "Bezahlte",
          to: "/:shopid/orders/completely_paid"
        },
        {
          name: "In Produktion",
          to: "/:shopid/orders/in_production"
        },
        */
      ],
    },
    {
      name: "menu.shoppingcart.list",
      to: "/:shopid/customer/shoppingcartlist",
      icon: "fa fa-shopping-cart",
    },
    /*
    {
      name: "Report",
      to: "/:shopid/report",
      icon: "fa fa-file-contract"
    }
    */
  ],
};
