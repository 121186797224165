import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Function to load state from localStorage
const loadState = () => {
  const state = {
    userJwt: "",
    username: "",
    userRole: "",
    name: "",
    shopid: "",
    shopJwt: "",
    shopList: [],
  };

  for (const key in state) {
    if (localStorage.getItem(key)) {
      state[key] = localStorage.getItem(key);
      if (key === "shopList") {
        state[key] = JSON.parse(state[key]);
      }
    }
  }

  return state;
};

export default new Vuex.Store({
  state: loadState(),
  mutations: {
    setJwt(state, userJwt) {
      state.userJwt = userJwt;

      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // Set the expiration to 12 hours from now

      if (userJwt === "") {
        Vue.$cookies.remove("token");
      } else {
        Vue.$cookies.set("token", userJwt, expirationDate);
      }

      localStorage.setItem("userJwt", userJwt);
    },
    setUsername(state, username) {
      state.username = username;
      localStorage.setItem("username", username);
    },
    setName(state, name) {
      state.name = name;
      localStorage.setItem("name", name);
    },
    setUserRole(state, userRole) {
      state.userRole = userRole;
      localStorage.setItem("userRole", userRole);
    },
    setShopId(state, shopId) {
      state.shopid = shopId;
      localStorage.setItem("shopid", shopId);
    },
    setShopJwt(state, shopJwt) {
      state.shopJwt = shopJwt;

      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // Set the expiration to 12 hours from now

      if (shopJwt === "") {
        Vue.$cookies.remove("shopToken");
      } else {
        Vue.$cookies.set("shopToken", shopJwt, expirationDate);
      }

      localStorage.setItem("shopJwt", shopJwt);
    },
    setShopList(state, shopList) {
      state.shopList = shopList;
      localStorage.setItem("shopList", JSON.stringify(shopList));
    },
  },
  actions: {
    setJwt(context, userJwt) {
      context.commit("setJwt", userJwt);
    },
    setUsername(context, username) {
      context.commit("setUsername", username);
    },
    setName(context, name) {
      context.commit("setName", name);
    },
    setUserRole(context, userRole) {
      context.commit("setUserRole", userRole);
    },
    setShopId(context, shopId) {
      context.commit("setShopId", shopId);
    },
    setShopJwt(context, shopJwt) {
      context.commit("setShopJwt", shopJwt);
    },
    setShopList(context, shopList) {
      context.commit("setShopList", shopList);
    },
  },
  modules: {},
  getters: {
    jwt: state => {
      return state.userJwt;
    },
    username: state => {
      return state.username;
    },
    name: state => {
      return state.name;
    },
    userRole: state => {
      return state.userRole;
    },
    shopId: state => {
      return state.shopid;
    },
    shopJwt: state => {
      return state.shopJwt;
    },
    shopList: state => {
      return state.shopList;
    },
  },
});
