class Notification {
  Vue = {};

  init(Vue) {
    this.Vue = Vue;
  }

  push(
    title,
    content,
    variant = "",
    append = false,
    toaster = "b-toaster-top-right",
    autoHideDelay = 5000,
  ) {
    if (title === "") {
      title =
        variant === "danger" && typeof variant === "string"
          ? "Error"
          : "Successful";
    }

    this.Vue.$bvToast.toast(content, {
      title: title,
      toaster: toaster,
      variant: variant,
      autoHideDelay: autoHideDelay,
      appendToast: append,
    });
  }
}

export default new Notification();
