<template>
  <div>
    <!-- Page Content -->
    <div class="content">
      <!-- Hero -->
      <base-block rounded content-full>
        <div class="py-1 text-center">
          <h1 class="mb-0 my-0 py-0">
            {{ $t("customer.shoppingcartlist.title") }}
          </h1>
          <h2 class="h5 font-w300 mb-0">
            {{ $t("customer.shoppingcartlist.subtitle") }}
          </h2>
        </div>
      </base-block>
      <!-- END Hero -->

      <!-- Shopping Cart Items -->
      <b-row>
        <b-col sm="12">
          <base-block rounded>
            <b-row>
              <b-col sm="12">
                <b-table-simple
                  striped
                  hover
                  borderless
                  class="table-vcenter font-size-sm mb-0"
                >
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center">{{
                        $t("customer.shoppingcartlist.created_at")
                      }}</b-th>
                      <b-th class="text-center">{{
                        $t("customer.shoppingcartlist.article")
                      }}</b-th>
                      <b-th class="text-center">{{
                        $t("customer.shoppingcartlist.action")
                      }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="cart in filteredList" :key="cart.timestamp">
                      <b-td class="text-center">{{
                        $d(new Date(cart.datetime), "long")
                      }}</b-td>
                      <b-td class="text-center">
                        <span v-for="item in cart.items" :key="item.itemnumber">
                          <b-row class="py-2">
                            <b-col
                              ><img :src="item.details.image[0]" width="150"
                            /></b-col>
                            <b-col class="my-auto">
                              <label
                                >{{
                                  $t("customer.shoppingcartlist.itemnumber")
                                }}:</label
                              >
                              {{ item.itemnumber }}
                              <br />
                              <label
                                >{{
                                  $t("customer.shoppingcartlist.size")
                                }}:</label
                              >
                              {{ item.size }}</b-col
                            >
                          </b-row>
                        </span>
                      </b-td>
                      <b-td class="text-center">
                        <router-link
                          :to="{
                            name: 'customerShoppingCart',
                            params: { carttimestamp: cart.timestamp },
                          }"
                          class="btn btn-sm btn-primary"
                        >
                          {{ $t("customer.shoppingcartlist.open_cart") }}
                        </router-link></b-td
                      >
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
      <!-- END Shopping Cart Items-->
    </div>
    <!-- END Page Content -->
  </div>
</template>

<script>
import _ from "lodash";
import Loading from "@/store/loading";
import ShoppingCartList from "@/assets/js/ShoppingCartList";

export default {
  name: "CustomerShoppingCartList",
  data() {
    return {
      list: [],
      filter: "all",
      tableSortKey: "timestamp",
      tableSortDirection: "desc",
    };
  },
  mounted() {
    this.shopId = this.$route.params.shopid;

    Loading.dispatch("increase");
    this.showList();
  },
  methods: {
    loadList() {
      return ShoppingCartList.getList(this.shopId);
    },
    showList() {
      this.loadList().then(data => {
        this.list = data;
        Loading.dispatch("reduce");
      });
    },
    tableSortBy(sortKey) {
      if (sortKey == this.tableSortKey) {
        if (this.tableSortDirection == "asc") {
          this.tableSortDirection = "desc";
        } else {
          this.tableSortDirection = "asc";
        }
      } else {
        this.tableSortKey = sortKey;
        this.tableSortDirection = "asc";
      }
    },
  },
  watch: {},
  computed: {
    filteredList() {
      if (this.filter != "all" && this.filter != "") {
        return _.orderBy(
          this.list.filter,
          [this.tableSortKey],
          [this.tableSortDirection],
        );
      }

      return _.orderBy(
        this.list,
        [this.tableSortKey],
        [this.tableSortDirection],
      );
    },
  },
};
</script>
