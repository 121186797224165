<template>
  <div id="app">
    <!-- App Loader -->
    <div id="app-loader" :class="{ show: $store.state.settings.appLoader }">
      <h2 class="text-center">{{ $t("layout.appLoader") }}</h2>
    </div>
    <!-- END App Loader -->

    <section v-if="!appLoader">
      <section v-if="shopJwt && routeName == 'customerShoppingCart'">
        <router-view />
      </section>
      <section v-else-if="!jwt">
        <Login :route-name="routeName" />
      </section>
      <section v-else>
        <router-view />
      </section>
    </section>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";

import Store from "@/store";
import AuthStore from "@/store/auth";

import DbInit from "@/assets/js/DbInit";
import Auth from "@/assets/js/Auth";

export default {
  name: "App",
  async created() {
    await this.initializeDatabase();
    await this.handleShopAuthentication();
    await this.handleUserAuthentication();
  },
  mounted() {
    this.checkShopIdInRoute();
    this.checkUserData();
  },
  updated() {
    this.checkShopIdInRoute();
  },
  methods: {
    async initializeDatabase() {
      try {
        await DbInit.sendInitRequest();
        Store.commit("appLoader", { mode: "off" });
      } catch (error) {
        this.handleError("Error initializing the database:", error);
      }
    },

    async handleShopAuthentication() {
      const shopJwt = this.getShopJwt();

      if (shopJwt) {
        try {
          await AuthStore.dispatch("setShopJwt", shopJwt);
          const shopAuthResult = await Auth.checkShopAuth();
          this.logResult("Shop Authentication Result", shopAuthResult);
          if (shopAuthResult === false) {
            Auth.shopLogout();
          }
        } catch (error) {
          this.handleError("Error during shop authentication:", error);
        }
      }
    },

    async handleUserAuthentication() {
      const jwt = this.$cookies.get("token");

      if (jwt) {
        const username = this.username || this.$cookies.get("username");
        try {
          await AuthStore.dispatch("setJwt", jwt);
          const userAuthResult = await Auth.checkAuth(username);
          if (userAuthResult === false) {
            this.$cookies.remove("token");
            Auth.logout();
          }
        } catch (error) {
          this.handleError("Error during user authentication:", error);
        }
      }
    },

    checkShopIdInRoute() {
      const routesToCheck = [
        "customerShoppingCart",
        "customerOrderLogin",
        "manufactureHome",
        "manufactureArchive",
        "manufactureOrder",
        "manufactureSearchResult",
      ];

      // Check if the current route name is in the list of routes that require redirection
      if (routesToCheck.includes(this.$route.name)) {
        return;
      }

      let username = "";

      if (this.username) {
        username = this.username;
      } else if (this.$cookies.get("username")) {
        username = this.$cookies.get("username");
      }

      if (AuthStore.getters.jwt && this.isShopIdMissingInRoute()) {
        this.handleMissingShopId(username);
      }
    },

    async checkUserData() {
      if (this.isJwtAvailable() && this.isUsernameMissing()) {
        try {
          const result = await Auth.userdata(this.username);
          this.logResult("UserData Result", result);
        } catch (error) {
          this.logError("checkUserData not okay");
        }
      }
    },

    // Helper methods
    isJwtAvailable() {
      return AuthStore.getters.jwt !== "";
    },

    isShopIdMissingInRoute() {
      return (
        !this.$route.params["shopid"] ||
        this.$route.params["shopid"] === "undefined" ||
        typeof this.$route.params["shopid"] === "undefined"
      );
    },

    handleMissingShopId(username) {
      Auth.userdata(username)
        .then(result => {
          if (result === false) {
            // user is not authorized. Logout.
            Auth.logout();
            this.navigateTo("/");
          } else {
            // user is authorized. Redirect to shop.
            this.navigateTo("/" + result.shopid);
          }
        })
        .catch(() => {
          Auth.logout();
          this.navigateTo("/");
        });
    },

    navigateTo(path) {
      this.$router.push(path).catch(error => {
        this.handleNavigationError(error);
      });
    },

    handleNavigationError(error) {
      if (
        error.name !== "NavigationDuplicated" &&
        !error.message.includes(
          "Avoided redundant navigation to current location",
        )
      ) {
        this.logError(error);
      }
    },

    isUsernameMissing() {
      return (
        AuthStore.getters.username == "" ||
        typeof AuthStore.getters.username == "undefined"
      );
    },

    getShopJwt() {
      const hashJwt = location.hash.substring(1); // Get JWT from location hash
      if (hashJwt) {
        return hashJwt;
      }

      // If there's no JWT in the location hash return the JWT from the cookie
      return this.$cookies.get("shopToken");
    },

    logResult(message, result) {
      console.log(`${message}:`, result);
    },

    logError(message) {
      console.error(message);
    },

    handleError(message, error) {
      this.logError(message);
      console.log(error);
    },
  },
  components: {
    Login,
  },
  computed: {
    jwt() {
      return AuthStore.getters.jwt;
    },
    username() {
      return AuthStore.getters.username;
    },
    shopJwt() {
      return AuthStore.getters.shopJwt;
    },
    routeName() {
      return this.$route.name;
    },
    appLoader() {
      return Store.state.settings.appLoader;
    },
  },
};
</script>

<style lang="scss">
// Main Stylesheet
@import "./src/assets/scss/main";

// Bootstrap Vue Stylesheet
@import "~bootstrap-vue/src/index";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;

  .text-green {
    color: green !important;
  }

  .text-red {
    color: red !important;
  }

  .text-black {
    color: #000 !important;
  }

  .text-bold {
    font-weight: 600;
  }

  .font-small {
    font-size: 0.9em;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .opacity-light {
    opacity: 0.2; /* Der Text kann kaum über dem Hintergrund gelesen werden */
  }

  .opacity-medium {
    opacity: 0.5; /* Der Text ist besser über dem Hintergrund lesbar */
  }

  .opacity-heavy {
    opacity: 0.9; /* Der Text ist sehr deutlich über dem Hintergrund lesbar */
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
