var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('base-background',{attrs:{"image":_vm.headerImage,"inner-class":"bg-secondary-dark-op"}},[_c('div',{staticClass:"content content-narrow content-full"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mt-5 mb-2 text-center text-sm-left"},[_c('div',{staticClass:"flex-sm-fill"},[_c('h1',{staticClass:"font-w600 text-white mb-0"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.title"))+" ")]),_c('h2',{staticClass:"h4 font-w400 text-white-75 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.subtitle"))+" ")])])])])]),_c('div',{staticClass:"content content-narrow"},[_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('base-block',{attrs:{"rounded":"","title":_vm.$t('dashboard.boxes.latestOrders.title'),"header-bg":"","content-full":""}},[_c('b-table-simple',{staticClass:"table-vcenter font-size-sm mb-0",attrs:{"striped":"","hover":"","borderless":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"font-w700"},[_vm._v(_vm._s(_vm.$t("dashboard.boxes.latestOrders.orderNumber")))]),_c('b-th',{staticClass:"d-none d-sm-table-cell font-w700"},[_vm._v(_vm._s(_vm.$t("dashboard.boxes.latestOrders.deliveryDate")))]),_c('b-th',{staticClass:"font-w700"},[_vm._v(_vm._s(_vm.$t("dashboard.boxes.latestOrders.commission")))]),_c('b-th',{staticClass:"font-w700"},[_vm._v(_vm._s(_vm.$t("dashboard.boxes.latestOrders.status")))])],1)],1),(_vm.filteredList.length > 0)?_c('b-tbody',_vm._l((_vm.filteredList),function(order){return _c('b-tr',{key:order.order_id_str},[_c('b-td',[(order.shop_id && order.order_id_str)?_c('router-link',{staticClass:"font-w600",attrs:{"to":{
                      name: 'OrderDetail',
                      params: {
                        shopid: order.shop_id,
                        orderid: order.order_id_str,
                      },
                    }}},[_vm._v(" "+_vm._s(order.order_id_str)+" ")]):_vm._e()],1),_c('b-td',{staticClass:"d-none d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.$d(new Date(order.delivery_date_supplier), "short"))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(order.commission)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.$t("orders.status." + order.current_status))+" ")])],1)}),1):_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_c('div',{staticClass:"py-5"},[_c('p',{staticClass:"font-size-h3 font-w300 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.boxes.latestOrders.noOrders"))+" ")])])])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }