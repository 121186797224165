import AuthStore from "@/store/auth";

class Auth {
  login(username, password) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "login";

      let data = {
        username: username,
        password: password,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      fetch(url, requestOptions).then(response => {
        if (response.status === 200) {
          response.json().then(json => {
            if (json.token !== false) {
              AuthStore.dispatch("setJwt", json.token).catch(err => {
                console.error(err);
                resolve(false);
              });

              AuthStore.dispatch("setName", json.name);
              AuthStore.dispatch("setUsername", json.username);
              AuthStore.dispatch("setUserRole", json.role);
              AuthStore.dispatch("setShopId", json.shopid);
              AuthStore.dispatch("setShopList", json.shoplist);

              resolve(json);
            } else {
              resolve(false);
            }
          });
        } else if (response.status === 401) {
          resolve(false);
        }
      });
    });
  }

  checkAuth() {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "token/check/user";

      let options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + AuthStore.getters.jwt,
          "Content-Type": "application/json",
        },
      };

      fetch(url, options).then(response => {
        if (response.status === 200) {
          response.json().then(json => {
            if (json.auth === "false") {
              resolve(false);
            } else {
              resolve(true);
            }
          });
        } else {
          resolve(false);
        }
      });
    });
  }

  checkShopAuth() {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "token/check/shop";

      let options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + AuthStore.getters.shopJwt,
          "Content-Type": "application/json",
        },
      };

      fetch(url, options).then(response => {
        if (response.status === 200) {
          response.json().then(json => {
            if (json.auth === "false") {
              AuthStore.dispatch("setShopJwt", "");
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
      });
    });
  }

  userdata(username) {
    return new Promise(resolve => {
      let url = process.env.VUE_APP_API + "user/" + username;

      let options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + AuthStore.getters.jwt,
          "Content-Type": "application/json",
        },
      };

      fetch(url, options).then(response => {
        if (response.status === 200) {
          response.json().then(json => {
            AuthStore.dispatch("setUsername", json.username).catch(err => {
              console.error(err);
              resolve(false);
            });

            AuthStore.dispatch("setName", json.name).catch(err => {
              console.error(err);
              resolve(false);
            });

            AuthStore.dispatch("setUserRole", json.role).catch(err => {
              console.error(err);
              resolve(false);
            });

            AuthStore.dispatch("setShopId", json.shopid).catch(err => {
              console.error(err);
              resolve(false);
            });

            AuthStore.dispatch("setShopList", json.shoplist).catch(err => {
              console.error(err);
              resolve(false);
            });

            resolve(json);
          });
        } else if (response.status === 400) {
          resolve(false);
        } else if (response.status === 401) {
          resolve(false);
        }
      });
    });
  }

  logout() {
    AuthStore.dispatch("setJwt", "");
    AuthStore.dispatch("setName", "");
    AuthStore.dispatch("setUserRole", "");
    AuthStore.dispatch("setShopId", "");
    AuthStore.dispatch("setShopList", []);
    AuthStore.dispatch("setShopJwt", "");
  }

  shopLogout() {
    AuthStore.dispatch("setShopJwt", "");
    AuthStore.dispatch("setShopId", "");
  }
}

export default new Auth();
